export const firstAccordion = 'firstAccordion';
export const secondAccordion = 'secondAccordion';
export const thirdAccordion = 'thirdAccordion';

export type AccordionExpanded =
  | typeof firstAccordion
  | typeof secondAccordion
  | typeof thirdAccordion;

export interface SurveyAccordionProps {
  expanded: boolean;
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onSelect: (index: number) => () => void;
  title: string;
  description: string;
}

export type Question = {
  title: string;
  description: string;
  id: AccordionExpanded;
};

export const surveySections = [
  'employmentAccess',
  'training',
  'mobility',
  'housing',
  'health',
  'financial',
  'citizenship',
] as const;
export type SurveySection = typeof surveySections[number];

export type StatusSection = 'inProgress' | 'toDo' | 'done';
