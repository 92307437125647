import { Stack, Typography } from '@mui/material';
import { theme } from '~/shared/theme/theme';
import { ValuesType } from '~/shared/types';

const AutonomyLevel = {
  Level1: 'Level1',
  Level2: 'Level2',
  Level3: 'Level3',
  Level4: 'Level4',
  Level5: 'Level5',
} as const;
type AutonomyLevel = ValuesType<typeof AutonomyLevel>;

const autonomyLevelPath: Record<AutonomyLevel, string> = {
  [AutonomyLevel.Level1]: '/autonomy_level_1_picto.svg',
  [AutonomyLevel.Level2]: '/autonomy_level_2_picto.svg',
  [AutonomyLevel.Level3]: '/autonomy_level_3_picto.svg',
  [AutonomyLevel.Level4]: '/autonomy_level_4_picto.svg',
  [AutonomyLevel.Level5]: '/autonomy_level_5_picto.svg',
};

const autonomyLevelLegend: Record<AutonomyLevel, string> = {
  [AutonomyLevel.Level5]: 'Autonomie',
  [AutonomyLevel.Level4]: 'Mobilisation',
  [AutonomyLevel.Level3]: 'Connaissance',
  [AutonomyLevel.Level2]: 'Intérêt',
  [AutonomyLevel.Level1]: '',
};

export const AutonomyLevelsIcons = () => {
  return (
    <Stack
      alignSelf="stretch"
      alignItems="end"
      justifyContent="space-between"
      paddingBottom={theme.spacing(9)}
    >
      {Object.values(AutonomyLevel)
        .reverse()
        .map((level) => (
          <Stack key={level} alignItems="center" gap={theme.spacing(1)}>
            <img
              src={autonomyLevelPath[level]}
              alt={autonomyLevelPath[level]}
              style={{ width: '100px', minWidth: '100px' }}
            />
            <Typography variant="body2" color="textSecondary">
              {autonomyLevelLegend[level]}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};
