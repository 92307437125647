import { LoadingButtonProps } from '@mui/lab';
import { Stack, TooltipProps } from '@mui/material';
import { StyledButton } from '~/shared/components/StyledButton';
import { StyledTooltip } from '~/shared/components/StyledTooltip';

export type StyledButtonWithTooltipProps = LoadingButtonProps & {
  tooltipProps: Omit<TooltipProps, 'children'>;
};
export const StyledButtonWithTooltip = ({
  tooltipProps,
  ...buttonProps
}: StyledButtonWithTooltipProps) => {
  return (
    <StyledTooltip placement="top" {...tooltipProps}>
      <Stack>
        <StyledButton {...buttonProps}>{buttonProps.children}</StyledButton>
      </Stack>
    </StyledTooltip>
  );
};
