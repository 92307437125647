export type ProgressionDataPoint = {
  observationNumber: number;
  observationDate: string; // French formatted date string
  question1: number | null;
  question2: number | null;
  question3: number | null;
};

export type CategoriesDataPoint = Pick<
  ProgressionDataPoint,
  'observationNumber' | 'observationDate'
>;

export type QuestionsSeriesDataPoint = Pick<
  ProgressionDataPoint,
  'question1' | 'question2' | 'question3'
>;

export const questionsSeriesDataPointDefaultValue: QuestionsSeriesDataPoint = {
  question1: null,
  question2: null,
  question3: null,
};

export type ProgressionDatasetDimension = keyof ProgressionDataPoint;
