import { useKeycloak } from '@react-keycloak/web';
import { ConnectionPage } from '~/modules/connection/views/ConnectionPage';
import { LoadingPage } from './LoadingPage';

const PrivateRoute = (props: { children: JSX.Element }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;
  if (isLoggedIn === undefined) {
    return <LoadingPage />;
  } else if (isLoggedIn === true) {
    return props.children;
  } else {
    return <ConnectionPage />;
  }
};

export default PrivateRoute;
