import { Stack, StackProps, styled } from '@mui/material';

export const StyledAutonomyProgressionEmptyContainer = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  gap: theme.spacing(6),
}));
