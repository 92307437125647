import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_DASHBOARD_PAGE_ROUTE_PATH } from '~/core/router';
import { AdminAuthService } from '~/modules/admin/infrastructure/adminAuthService';
import { firebaseAuthService } from '~/modules/admin/infrastructure/firebaseAdminAuthService';
import { StyledAdminConnexionPage } from '~/modules/admin/views/AdminPage.styled';
import { useEmailTextField } from '~/shared/components/hooks/useEmailTextField';
import { StyledButton } from '~/shared/components/StyledButton';
import { StyledTextField } from '~/shared/components/StyledTextfield';
import { theme } from '~/shared/theme/theme';

const PAGE_TITLE = "Se connecter en tant qu'administrateur";
export const TEXT_FIELD_EMAIL_TITLE = 'Email';
const TEXT_FIELD_EMAIL_PLACEHOLDER = 'jean.dupont@gmail.com';
export const TEXT_FIELD_PASSWORD_TITLE = 'Mot de passe';
export const BUTTON_CONNEXION_TITLE = 'Connexion';
export const CONNEXION_ERROR_LABEL = 'Email et/ou mot de passe incorrect(s)';

type Props = {
  adminAuthService?: AdminAuthService;
};

export const AdminConnexionPage = ({ adminAuthService = firebaseAuthService }: Props) => {
  const { email, isValidEmail, helperText, handleEmailChange } = useEmailTextField();
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { status: adminConnexionStatus } = adminAuthService.useAdminConnexionStatus();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (adminConnexionStatus === 'CONNECTED') {
      navigate(ADMIN_DASHBOARD_PAGE_ROUTE_PATH);
    }
  });

  return (
    <StyledAdminConnexionPage>
      <Typography variant="h5" paddingBottom={theme.spacing(10)}>
        {PAGE_TITLE}
      </Typography>
      <Stack direction="column" component={'form'} width={300} gap={theme.spacing(7)}>
        <StyledTextField
          value={email}
          label={TEXT_FIELD_EMAIL_TITLE}
          size="small"
          type="email"
          inputProps={{
            'aria-label': TEXT_FIELD_EMAIL_TITLE,
          }}
          onChange={handleEmailChange}
          placeholder={TEXT_FIELD_EMAIL_PLACEHOLDER}
          helperText={helperText}
          error={helperText !== ''}
        />
        <StyledTextField
          label={TEXT_FIELD_PASSWORD_TITLE}
          size="small"
          type="password"
          inputProps={{
            'aria-label': TEXT_FIELD_PASSWORD_TITLE,
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton
          color="primary"
          variant="contained"
          endIcon={<ArrowForwardRoundedIcon />}
          disabled={!isValidEmail || password === ''}
          onClick={async () => {
            const isSignedIn = await adminAuthService.signInAdminUser(email, password);
            if (!isSignedIn) {
              setErrorMessage(CONNEXION_ERROR_LABEL);
            }
          }}
        >
          {BUTTON_CONNEXION_TITLE}
        </StyledButton>
      </Stack>
      <Snackbar
        open={errorMessage !== undefined}
        onClose={() => setErrorMessage(undefined)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-label={CONNEXION_ERROR_LABEL}
      >
        <Alert severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </StyledAdminConnexionPage>
  );
};
