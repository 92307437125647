import { styled, Typography, TypographyProps } from '@mui/material';

export const StyledProgressTableDimmedTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'dimmed',
})<TypographyProps & { dimmed: boolean }>(({ theme, dimmed }) => ({
  ...theme.typography.body2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: dimmed ? theme.palette.grey[300] : 'initial',
}));
