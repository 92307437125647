import { Edit } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import {
  AddYoungAdultDialog,
  AddYoungAdultFormValues,
} from '~/modules/progressDashboard/components/AddYoungAdultDialog';
import { getSnackbarColor, getSnackbarMessage } from '~/modules/progressDashboard/domain/snackbar';
import { serializeNationalSupportPlan } from '~/modules/progressDashboard/domain/validateValues';
import { useYoungAdultMutations } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileMutations';
import { UserFileInfo } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { StyledButtonWithTooltip } from '~/shared/components/StyledButtonWithTooltip';
import { theme } from '~/shared/theme/theme';

const EDIT_BUTTON_LABEL_PREFIX = 'edit-button';
const EDIT_BUTTON_TOOLTIP_TEXT = 'Modifier les informations de cet usager';

const EDIT_YOUNG_ADULT_SNACKBAR_LABEL = 'edit-young-adult-snackbar';
const EDIT_YOUNG_ADULT_FORBIDDEN_MSG = "Vous n'avez pas l'autorisation de modifier cet usager";
const EDIT_YOUNG_ADULT_ERROR_MSG = 'Impossible de modifier cet usager';
const EDIT_YOUNG_ADULT_SUCCESS_MSG = "L'usager a bien été modifié";

export const EditYoungAdultButton = ({
  setLineClickDisabled,
  youngAdult,
}: {
  setLineClickDisabled: (value: boolean) => void;
  youngAdult: UserFileInfo;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSnackbarVisible, showSnackbar] = useState(false);
  const isLoading = false;

  const { editYoungAdultMutation } = useYoungAdultMutations();

  const onToggleModalOpen = (open: boolean) => {
    setIsDialogOpen(open);
    setLineClickDisabled(open);
  };

  const onSubmit = (values: AddYoungAdultFormValues) => {
    const serializedValues = {
      ...youngAdult,
      ...values,
      lastname: values.lastname.toUpperCase(),
      nationalSupportPlan: serializeNationalSupportPlan(values.nationalSupportPlan),
    };

    editYoungAdultMutation.mutate(serializedValues, { onSettled: () => showSnackbar(true) });

    onToggleModalOpen(false);
  };

  const defaultValues = {
    firstname: youngAdult.firstname,
    lastname: youngAdult.lastname,
    birthDate: new Date(youngAdult.birthDate),
    gender: youngAdult.gender,
    network: youngAdult.network,
    nationalSupportPlan: youngAdult.nationalSupportPlan,
    population: youngAdult.population,
  };

  const snackbarMessage = {
    forbidden: EDIT_YOUNG_ADULT_FORBIDDEN_MSG,
    error: EDIT_YOUNG_ADULT_ERROR_MSG,
    success: EDIT_YOUNG_ADULT_SUCCESS_MSG,
  };

  return (
    <>
      <StyledButtonWithTooltip
        onClick={(event) => {
          event.stopPropagation();
          onToggleModalOpen(true);
        }}
        loading={editYoungAdultMutation.isLoading}
        aria-label={`${EDIT_BUTTON_LABEL_PREFIX}-${youngAdult.imiloId}`}
        color="primary"
        variant="secondary"
        sx={{ marginLeft: theme.spacing(8), color: theme.palette.primary.main }}
        tooltipProps={{ title: EDIT_BUTTON_TOOLTIP_TEXT, placement: 'top', arrow: true }}
      >
        <Edit
          sx={{
            color: theme.palette.grey['300'],
            visibility: isLoading ? 'hidden' : 'inherit',
          }}
        />
      </StyledButtonWithTooltip>
      {isDialogOpen && (
        <AddYoungAdultDialog
          defaultValues={defaultValues}
          editMode
          onCancelClicked={() => setIsDialogOpen(false)}
          onClose={() => onToggleModalOpen(false)}
          onSubmitClicked={onSubmit}
          open={isDialogOpen}
        />
      )}
      {isSnackbarVisible && (
        <Snackbar
          open={isSnackbarVisible}
          onClose={() => showSnackbar(false)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          aria-label={EDIT_YOUNG_ADULT_SNACKBAR_LABEL}
        >
          <Alert severity={getSnackbarColor(editYoungAdultMutation.isSuccess)} variant="filled">
            {getSnackbarMessage(snackbarMessage, editYoungAdultMutation.error ?? undefined)}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
