import { Stack, StackProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { useParams } from 'react-router-dom';
import { PROGRESS_DASHBOARD_ROUTE_PATH } from '~/core/router';
import { extractYoungAdultFileInfo } from '~/modules/observation/components/ObservationNavBar/domain/extractYoungAdultInfo';
import { GeneratePdfButton } from '~/modules/observation/components/ObservationNavBar/views/GeneratePdfButton';
import { ProgressionSurveyBar } from '~/modules/observation/components/ObservationNavBar/views/ProgressionSurveyBar';
import { YoungAdultObservationInfoCard } from '~/modules/observation/components/ObservationNavBar/views/YoungAdultInfo';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { ObservationScores } from '~/modules/observation/domain/observationScores';
import { GoToSection } from '~/modules/observation/domain/useSectionManager';
import { useYoungAdultFileQuery } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileQuery';
import { useNavigateOnCondition } from '~/shared/components/hooks/useNavigateOnCondition';

const ThemedDrawer = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: '100%',
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(7),
  paddingRight: theme.spacing(7),
  overflow: 'auto',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  gap: theme.spacing(8),
  paddingTop: theme.spacing(13),
}));

const drawerWidth = 360;

type SideBarProps = {
  children: JSX.Element;
  currentDomain: ObservationDomain;
  observationScores: ObservationScores;
  goToSection: GoToSection;
};
export const SideBar = ({
  children,
  goToSection,
  currentDomain,
  observationScores,
}: SideBarProps) => {
  const { imiloId } = useParams();

  const { data: youngAdultFile, isLoading, isError } = useYoungAdultFileQuery(imiloId ?? '');
  const youngAdultInfo =
    youngAdultFile === undefined ? undefined : extractYoungAdultFileInfo(youngAdultFile);

  useNavigateOnCondition(isError === true, PROGRESS_DASHBOARD_ROUTE_PATH);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <ThemedDrawer>
          <YoungAdultObservationInfoCard isLoading={isLoading} youngAdultInfo={youngAdultInfo} />
          <ProgressionSurveyBar
            goToSection={goToSection}
            currentDomain={currentDomain}
            observationScores={observationScores}
          />
          <Stack flex={1} justifyContent="end">
            <GeneratePdfButton />
          </Stack>
        </ThemedDrawer>
      </Drawer>
      <Box>{children}</Box>
    </Box>
  );
};
