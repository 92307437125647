/**
 * This is a generic typing enforcement for React Query keys
 * From Hokla's best-in-class example (https://www.notion.so/m33/React-Query-super-strong-query-key-management-a2f515449e0a46eaa784525e5be59f17)
 */

const all = () => ['key_young_adult_files'] as const;
const byImiloId = (imiloId: string) => [...all(), 'by_imiloID', imiloId] as const;

export const getYoungAdultFilesQueryKey = {
  all,
  byImiloId,
} as const;
export type YoungAdultFileQueryKey = ReturnType<
  typeof getYoungAdultFilesQueryKey[keyof typeof getYoungAdultFilesQueryKey]
>;
