import { AccordionSummary, AccordionSummaryProps, Stack, Typography } from '@mui/material';
import { theme } from '~/shared/theme/theme';

export type QuestionSummaryProps = AccordionSummaryProps & {
  title: string;
  index: number;
  disabled: boolean;
};
export const QuestionSummary = ({ title, index, disabled, ...props }: QuestionSummaryProps) => {
  const textColor = disabled ? theme.palette.text.disabled : theme.palette.text.primary;

  return (
    <AccordionSummary {...props}>
      <Stack direction="row" gap={theme.spacing(4)}>
        <Typography variant="h6" color={textColor}>
          {index}
        </Typography>
        <Typography variant="h6" color={textColor}>
          {title}
        </Typography>
      </Stack>
    </AccordionSummary>
  );
};
