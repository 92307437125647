import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { theme } from '~/shared/theme/theme';

export const StyledButton = styled(LoadingButton)<LoadingButtonProps>(() => ({
  ...theme.typography.button,
  boxShadow: 'unset',
  textTransform: 'none',
  borderRadius: '8px',
  padding: '8px 16px',
  flexWrap: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}));
