import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Box, Typography } from '@mui/material';
import {
  hasNoYoungAdult,
  useYoungAdultFilesQuery,
} from '~/modules/progressDashboard/infrastructure';
import { AddYoungAdultForm } from '~/modules/progressDashboard/views/ProgressionDashboardCard/AddYoungAdultForm';
import { FollowYoungAdultForm } from '~/modules/progressDashboard/views/ProgressionDashboardCard/FollowYoungAdultForm';
import { ProgressionDashboardCard } from '~/modules/progressDashboard/views/ProgressionDashboardCard/ProgressionDashboardCard';
import { StyledProgressDashboardPage } from '~/modules/progressDashboard/views/StyledProgressDashboardPage.style';
import { theme } from '~/shared/theme/theme';

export const PROGRESS_DASHBOARD_TITLE = 'Tableau des progrès';
export const PROGRESS_DASHBOARD_PAGE_LABEL = 'progress-dashboard-label';

export const ProgressDashboardPage = () => {
  const { data: youngAdultList } = useYoungAdultFilesQuery();
  const isImiloEnabled = useFeatureIsOn('imilo-connection');

  return (
    <StyledProgressDashboardPage aria-label={PROGRESS_DASHBOARD_PAGE_LABEL}>
      <Box display="flex" gap={theme.spacing(9)} component="div" justifyContent="space-between">
        <Typography variant="h5" fontWeight={800}>
          {PROGRESS_DASHBOARD_TITLE}
        </Typography>

        {!hasNoYoungAdult(youngAdultList) && (
          <Box
            component="div"
            display="flex"
            gap={theme.spacing(5)}
            justifyContent="right"
            sx={{
              flexFlow: 'wrap',
            }}
          >
            {isImiloEnabled ? <FollowYoungAdultForm /> : <AddYoungAdultForm />}
          </Box>
        )}
      </Box>

      <ProgressionDashboardCard />
    </StyledProgressDashboardPage>
  );
};
