import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography } from '@mui/material';
import { populationTranslationWithDetails } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { StyledTooltip } from '~/shared/components/StyledTooltip';
import { theme } from '~/shared/theme/theme';

export const PopulationInfoIcon = () => {
  return (
    <StyledTooltip
      arrow
      placement="right"
      title={
        <Box padding={theme.spacing(1)}>
          {Object.values(populationTranslationWithDetails).map((text) => (
            <Typography key={text}>{text}</Typography>
          ))}
        </Box>
      }
    >
      <InfoIcon
        height="50px"
        sx={{ color: theme.palette.primary.main, marginLeft: theme.spacing(2) }}
      />
    </StyledTooltip>
  );
};
