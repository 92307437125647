import { AxiosError } from 'axios';

export type SnackbarColor = 'success' | 'error';

export const getSnackbarColor = (isSuccess: boolean): SnackbarColor =>
  isSuccess ? 'success' : 'error';

type SnackbarMessage = {
  success: string;
  alreadyFollowed?: string;
  forbidden?: string;
  error: string;
};
export const getSnackbarMessage = (snackbarMessage: SnackbarMessage, error?: AxiosError) => {
  if (error === undefined) return snackbarMessage.success;
  if (error.response?.status === 409) return snackbarMessage.alreadyFollowed;
  if (error.response?.status === 403) return snackbarMessage.forbidden;
  return snackbarMessage.error;
};
