// This custom hook is necessary until this issue is solved in react-router-dom v6
// https://github.com/remix-run/react-router/issues/8139

/**
 * Prompts a user when they exit the page
 */

import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useConfirmPageLeave = (confirmExit: () => boolean, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePageLeavePrompt = (message: string, when = true) => {
  // Handle tab closing
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  // Handle internal app navigation
  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmPageLeave(confirmExit, when);
};
