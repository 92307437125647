import axios from 'axios';
import { getAxiosAdminOptions } from '~/core/api/axiosInstance';

const getRoute = (imiloId: string) => `/young-adult-files/${imiloId}`;

export type DeleteYoungAdultParams = {
  imiloId: string;
  adminJwt: string;
};

export const deleteYoungAdult = async ({
  imiloId,
  adminJwt,
}: DeleteYoungAdultParams): Promise<void> =>
  axios.delete(getRoute(imiloId), getAxiosAdminOptions(adminJwt));
