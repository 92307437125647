import { SvgIconComponent } from '@mui/icons-material';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

export const observationDomains = [
  'employmentAccess',
  'training',
  'mobility',
  'housing',
  'health',
  'financial',
  'citizenship',
] as const;
export type ObservationDomain = typeof observationDomains[number];

export const observationDomainLabels: Record<ObservationDomain, string> = {
  employmentAccess: "Accès à l'emploi",
  training: 'Formation',
  mobility: 'Mobilité',
  housing: 'Logement',
  health: 'Santé',
  financial: 'Ressources financières',
  citizenship: 'Citoyenneté',
};

export const observationDomainIcons: Record<ObservationDomain, SvgIconComponent> = {
  employmentAccess: WorkOutlineOutlinedIcon,
  training: HistoryEduOutlinedIcon,
  mobility: TimeToLeaveOutlinedIcon,
  housing: HomeOutlinedIcon,
  health: HealthAndSafetyOutlinedIcon,
  financial: EuroSymbolOutlinedIcon,
  citizenship: GroupsOutlinedIcon,
};
