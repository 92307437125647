import axios from 'axios';
import { getAxiosOptions } from '~/core/api/axiosInstance';
import { getSubmitObservationDtoFromObservationScores } from '~/modules/observation/components/ObservationSection/infrastructure/submitObservation.dto';
import { ObservationScores } from '~/modules/observation/domain/observationScores';

export const getBaseRoute = (imiloId: string) => {
  return `/young-adult-files/${imiloId}/observations`;
};

export type SubmitObservationParams = {
  imiloId: string;
  observationScores: ObservationScores;
};
export const postSubmitObservation = async ({
  imiloId,
  observationScores,
}: SubmitObservationParams): Promise<null> => {
  const baseRoute = getBaseRoute(imiloId);
  const route = `${baseRoute}/current`;

  const payload = getSubmitObservationDtoFromObservationScores(observationScores, 'submitted');
  const options = getAxiosOptions();

  return axios.post(route, payload, options);
};
