/**
 * This is a generic typing enforcement for React Query keys
 * From Hokla's best-in-class example (https://www.notion.so/m33/React-Query-super-strong-query-key-management-a2f515449e0a46eaa784525e5be59f17)
 */

const byImiloId = (imiloId: string) =>
  ['autonomy_progression_data', 'by_imiloID', imiloId] as const;

export const getAutonomyProgressionQueryKey = {
  byImiloId,
} as const;
export type AutonomyProgressionQueryKey = ReturnType<
  typeof getAutonomyProgressionQueryKey[keyof typeof getAutonomyProgressionQueryKey]
>;
