import { List, ListItem } from '@mui/material';
import { getDomainObservationStatus } from '~/modules/observation/components/ObservationNavBar/domain/domainCompletion';
import { ProgressionSurveyStep } from '~/modules/observation/components/ObservationNavBar/views/ProgressionSurveyStep';
import {
  ObservationDomain,
  observationDomains,
} from '~/modules/observation/domain/observationDomains';
import { ObservationScores } from '~/modules/observation/domain/observationScores';
import { GoToSection } from '~/modules/observation/domain/useSectionManager';

interface ProgressionSurveyBarProps {
  currentDomain: ObservationDomain;
  observationScores: ObservationScores;
  goToSection: GoToSection;
}
export const ProgressionSurveyBar = ({
  currentDomain,
  observationScores,
  goToSection,
}: ProgressionSurveyBarProps) => {
  const progressionSurveyBar = observationDomains.map((domain, index) => (
    <ListItem key={`ObservationNavBar_${index}`} disablePadding>
      <ProgressionSurveyStep
        goToSection={goToSection}
        status={getDomainObservationStatus(observationScores[domain])}
        domain={domain}
        focused={domain === currentDomain}
      />
    </ListItem>
  ));

  return <List>{progressionSurveyBar}</List>;
};
