import { Stack } from '@mui/material';
import { ConnectionForm } from '~/modules/connection/views/ConnectionForm';

export const CONNECTION_PAGE_IMAGE_ROUTE_PATH = '/MILOrizons-Connection-Image.jpg';
export const CONNECTION_PAGE_IMAGE_LABEL = 'MILOrizons_Connection_Image';
export const CONNECTION_PAGE_LABEL = 'Page se connecter';

export const ConnectionPage = () => {
  return (
    <Stack direction="row" aria-label={CONNECTION_PAGE_LABEL} height="100%">
      <img
        src={CONNECTION_PAGE_IMAGE_ROUTE_PATH}
        aria-label={CONNECTION_PAGE_IMAGE_LABEL}
        alt={CONNECTION_PAGE_IMAGE_LABEL}
      />
      <ConnectionForm />
    </Stack>
  );
};
