import { format } from 'date-fns';
import {
  CategoriesDataPoint,
  ProgressionDataPoint,
  QuestionsSeriesDataPoint,
  questionsSeriesDataPointDefaultValue,
} from '~/modules/autonomyProgression/domain/progressionChartData.schema';
import { Observation } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';

export type ProgressionDataset = {
  datapoints: ProgressionDataPoint[];
  missingDataRanges: MissingDataRange[];
};

export const getAutonomyDomainProgressionDataset = (
  observations: Observation[],
  autonomyDomain: ObservationDomain
): ProgressionDataset => {
  const sortedObservations = observations.sort(sortObservationsBySubmissionDate);

  // Extract series
  const categoriesSeriesDatapoints = getCategoriesSeries(sortedObservations);
  const questionsSeriesDatapoints = getAutonomyDomainQuestionsSeries(
    sortedObservations,
    autonomyDomain
  );
  const missingDataRanges = getAutonomyDomainMissingDataRanges(sortedObservations, autonomyDomain);

  const datapoints: ProgressionDataPoint[] = categoriesSeriesDatapoints.map(
    (categoriesDatapoint, index) => ({
      ...categoriesDatapoint,
      ...questionsSeriesDatapoints[index],
    })
  );

  return { datapoints, missingDataRanges };
};

const sortObservationsBySubmissionDate = (observationA: Observation, observationB: Observation) => {
  const dateA = Date.parse(observationA.updatedAt);
  const dateB = Date.parse(observationB.updatedAt);
  return dateA - dateB;
};

export const getCategoriesSeries = (observations: Observation[]): CategoriesDataPoint[] => {
  const categoriesSeriesDatapoints: CategoriesDataPoint[] = observations.map(
    (observation, index) => ({
      observationNumber: index + 1,
      observationDate: format(new Date(observation.updatedAt), 'dd/MM/yyyy'),
    })
  );
  return categoriesSeriesDatapoints;
};

const isDomainScoresFullyCompleted = (domainScores: (number | null)[]) =>
  domainScores.length === 3 && domainScores.every((score) => score !== null && score !== 0);

export const getAutonomyDomainQuestionsSeries = (
  observations: Observation[],
  autonomyDomain: ObservationDomain
): QuestionsSeriesDataPoint[] => {
  const questionsSeriesDatapoints: QuestionsSeriesDataPoint[] = observations.map((observation) => {
    const domainScores = observation[`${autonomyDomain}Autonomy`];

    if (isDomainScoresFullyCompleted(domainScores))
      return {
        question1: domainScores[0],
        question2: domainScores[1],
        question3: domainScores[2],
      };

    return questionsSeriesDataPointDefaultValue;
  });

  return questionsSeriesDatapoints;
};

export type MissingDataRange = { leftIndex: number; rightIndex: number };
export const getAutonomyDomainMissingDataRanges = (
  observations: Observation[],
  autonomyDomain: ObservationDomain
): MissingDataRange[] => {
  const missingDataRanges: MissingDataRange[] = [];

  const observationIndicesWithoutData = observations.reduce(
    (previousValue: number[], observation: Observation, index: number) => {
      const domainScores = observation[`${autonomyDomain}Autonomy`];
      if (!isDomainScoresFullyCompleted(domainScores)) previousValue.push(index);
      return previousValue;
    },
    []
  );

  let currentRange: MissingDataRange | null = null;
  observationIndicesWithoutData.forEach((observationIndex, index) => {
    if (currentRange === null) {
      currentRange = {
        leftIndex: Math.max(observationIndex - 1, 0),
        rightIndex: Math.min(observationIndex + 1, observationIndicesWithoutData.length - 1),
      };
    }

    const isLastObservation = observationIndex === observations.length - 1;
    if (isLastObservation) {
      currentRange.rightIndex = observationIndex;
      missingDataRanges.push(currentRange);
      currentRange = null;
      return;
    }

    const isLastOne = index === observationIndicesWithoutData.length - 1;
    if (isLastOne) {
      currentRange.rightIndex = observationIndex + 1;
      missingDataRanges.push(currentRange);
      currentRange = null;
      return;
    }

    const isNextOneConsecutive = observationIndicesWithoutData[index + 1] === observationIndex + 1;
    if (!isNextOneConsecutive) {
      currentRange.rightIndex = observationIndex + 1;
      missingDataRanges.push(currentRange);
      currentRange = null;
      return;
    }
  });

  return missingDataRanges;
};
