import { StyledButton } from '~/shared/components/StyledButton';

export const SKIP_BUTTON_LABEL = 'skip-button';
export const SKIP_BUTTON_TEXT = 'Pas de besoin dans ce domaine';

export type SkipDomainObservationButtonProps = {
  onClick: () => void;
};

export const SkipDomainObservationButton = ({ onClick }: SkipDomainObservationButtonProps) => (
  <StyledButton color="primary" variant="outlined" aria-label={SKIP_BUTTON_LABEL} onClick={onClick}>
    {SKIP_BUTTON_TEXT}
  </StyledButton>
);
