import Cookies from 'js-cookie';
import { USER_IDTOKEN_COOKIE_KEY } from '~/core/auth/AuthContext';

const baseUrl = import.meta.env.VITE_BACKEND_URL;

export const getUserIdToken = () => {
  const userIdToken = Cookies.get(USER_IDTOKEN_COOKIE_KEY);
  return userIdToken;
};

export const getAxiosOptions = () => {
  const userIdToken = getUserIdToken();
  return {
    baseURL: baseUrl,
    headers: {
      Authorization: 'Bearer ' + userIdToken,
    },
  };
};

export const getAxiosAdminOptions = (adminJwt: string) => ({
  baseURL: baseUrl,
  headers: {
    Authorization: 'Bearer ' + adminJwt,
  },
});
