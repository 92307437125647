import { SurveyButtonProps } from '~/modules/observation/components/ObservationSection/views/SurveyButton';

const FIRST_SURVEY_BUTTON_DESCRIPTION_1 = "Ne voit pas l'intérêt";
const FIRST_BUTTON_IMAGE_PATH = '/first_image_button.svg';

const SECOND_SURVEY_BUTTON_DESCRIPTION_1 = 'Voit';
const SECOND_SURVEY_BUTTON_DESCRIPTION_2_BOLD = " l'intérêt";
const SECOND_SURVEY_BUTTON_DESCRIPTION_3 = ' mais ne connaît pas encore';
const SECOND_BUTTON_IMAGE_PATH = '/second_image_button.svg';

const THIRD_SURVEY_BUTTON_DESCRIPTION_1_BOLD = 'Connaît';
const THIRD_SURVEY_BUTTON_DESCRIPTION_2 = ' mais ne mobilise pas encore';
const THIRD_BUTTON_IMAGE_PATH = '/third_image_button.svg';

const FOURTH_BUTTON_DESCRIPTION_1 = 'Connaît et';
const FOURTH_BUTTON_DESCRIPTION_2_BOLD = ' mobilise';
const FOURTH_BUTTON_DESCRIPTION_3 = ' de façon accompagnée';
const FOURTH_BUTTON_IMAGE_PATH = '/fourth_image_button.svg';

const FIFTH_BUTTON_DESCRIPTION_1 = 'Mobilise en';
const FIFTH_BUTTON_DESCRIPTION_2_BOLD = ' autonomie';
const FIFTH_BUTTON_IMAGE_PATH = '/fifth_image_button.svg';

export type AutonomyLevelProps = Omit<SurveyButtonProps, 'onClick' | 'selected'>;
export const autonomyLevels: AutonomyLevelProps[] = [
  {
    labels: [
      {
        text: FIRST_SURVEY_BUTTON_DESCRIPTION_1,
      },
    ],
    image: FIRST_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      { text: SECOND_SURVEY_BUTTON_DESCRIPTION_1 },
      {
        text: SECOND_SURVEY_BUTTON_DESCRIPTION_2_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
      { text: SECOND_SURVEY_BUTTON_DESCRIPTION_3 },
    ],
    image: SECOND_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      {
        text: THIRD_SURVEY_BUTTON_DESCRIPTION_1_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
      {
        text: THIRD_SURVEY_BUTTON_DESCRIPTION_2,
      },
    ],
    image: THIRD_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      {
        text: FOURTH_BUTTON_DESCRIPTION_1,
      },
      {
        text: FOURTH_BUTTON_DESCRIPTION_2_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
      {
        text: FOURTH_BUTTON_DESCRIPTION_3,
      },
    ],
    image: FOURTH_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      {
        text: FIFTH_BUTTON_DESCRIPTION_1,
      },
      {
        text: FIFTH_BUTTON_DESCRIPTION_2_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: FIFTH_BUTTON_IMAGE_PATH,
  },
];
