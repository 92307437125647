import axios from 'axios';
import { getAxiosOptions } from '~/core/api/axiosInstance';
import {
  UserFileInfo,
  YoungAdultFile,
  YoungAdultFileWithObservationSummary,
} from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';

export const getBaseRoute = () => `/young-adult-files`;

export type FollowYoungAdultParams = {
  imiloId: string;
};
export const followImiloYoungAdult = ({
  imiloId,
}: FollowYoungAdultParams): Promise<{ data: YoungAdultFile }> => {
  const baseRoute = getBaseRoute();
  const route = `${baseRoute}/imilo`;
  const options = getAxiosOptions();
  return axios.post(route, { imiloId }, options);
};

export type UnfollowYoungAdultParams = { imiloId: string };
export const unfollowYoungAdult = ({ imiloId }: UnfollowYoungAdultParams): Promise<null> => {
  const baseRoute = getBaseRoute();
  const route = `${baseRoute}/${imiloId}/unfollow`;
  const options = getAxiosOptions();
  return axios.patch(route, undefined, options);
};

export type AddYoungAdultParams = UserFileInfo;
export const addYoungAdult = (
  youngAdult: AddYoungAdultParams
): Promise<{ data: YoungAdultFile }> => {
  const baseRoute = getBaseRoute();
  const route = `${baseRoute}/add`;
  const options = getAxiosOptions();
  return axios.post(route, youngAdult, options);
};

export type EditYoungAdultParams = UserFileInfo;
export const editYoungAdult = (
  youngAdult: AddYoungAdultParams
): Promise<{ data: YoungAdultFile }> => {
  const baseRoute = getBaseRoute();
  const route = `${baseRoute}/edit`;
  const options = getAxiosOptions();
  return axios.post(route, youngAdult, options);
};

export const getAllYoungAdults = (): Promise<{
  data: YoungAdultFileWithObservationSummary[];
}> => {
  const route = getBaseRoute();
  const options = getAxiosOptions();
  return axios.get(route, options);
};

export const getYoungAdult = (
  imiloId: string
): Promise<{
  data: YoungAdultFileWithObservationSummary;
}> => {
  const baseRoute = getBaseRoute();
  const route = `${baseRoute}/${imiloId}`;
  const options = getAxiosOptions();
  return axios.get(route, options);
};
