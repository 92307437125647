import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Box, TableBody } from '@mui/material';
import { YoungAdultSortingColumn } from '~/modules/progressDashboard/domain/sortYoungAdults';
import { AddYoungAdultForm } from '~/modules/progressDashboard/views/ProgressionDashboardCard/AddYoungAdultForm';
import { FollowYoungAdultForm } from '~/modules/progressDashboard/views/ProgressionDashboardCard/FollowYoungAdultForm';
import { StyledCenteredTableContent } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledCenteredTableContent.style';
import { StyledEmptyYoungListMessage } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledEmptyYoungListMessage.style';
import {
  StyledProgressTableBodyCell,
  StyledProgressTableRow,
} from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledTable.style';
import { theme } from '~/shared/theme/theme';

const EMPTY_YOUNG_LIST_IMG = './empty-young-list.svg';
const EMPTY_YOUNG_LIST_IMG_LABEL = 'aucun-usager-trouve';
export const EMPTY_YOUNG_LIST = "Vous n'avez pas encore d'usagers en suivi.";
export const YOUNG_ADULT_TABLE_ROW_LABEL = 'user-table-row-label';
export const YOUNG_ADULT_FILE_TABLE_BODY = 'user-file-table-body';

export const EmptyYoungAdultTable = () => {
  const isImiloEnabled = useFeatureIsOn('imilo-connection');

  const columnNumber = Object.values(YoungAdultSortingColumn).length;

  return (
    <TableBody>
      <StyledProgressTableRow clickable={false}>
        <StyledProgressTableBodyCell colSpan={columnNumber}>
          <StyledCenteredTableContent>
            <img src={EMPTY_YOUNG_LIST_IMG} alt={EMPTY_YOUNG_LIST_IMG_LABEL} />
            <StyledEmptyYoungListMessage>{EMPTY_YOUNG_LIST}</StyledEmptyYoungListMessage>
            <Box component="div" display="flex" gap={theme.spacing(5)} alignItems="center">
              {isImiloEnabled ? (
                <FollowYoungAdultForm alignItems="center" direction="column" />
              ) : (
                <AddYoungAdultForm />
              )}
            </Box>
          </StyledCenteredTableContent>
        </StyledProgressTableBodyCell>
      </StyledProgressTableRow>
    </TableBody>
  );
};
