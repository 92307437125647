import { useQuery } from '@tanstack/react-query';
import { getAllYoungAdults, getYoungAdult } from '~/modules/progressDashboard/infrastructure/api';
import { YoungAdultFileWithObservationSummary } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { getYoungAdultFilesQueryKey } from '~/modules/progressDashboard/infrastructure/youngAdultFilesQueryKeys';

export const hasNoYoungAdult = (
  youngAdultList: YoungAdultFileWithObservationSummary[] | undefined
) => {
  return youngAdultList === undefined || youngAdultList.length === 0;
};

export const useYoungAdultFilesQuery = () => {
  const queryKey = getYoungAdultFilesQueryKey.all();
  const query = useQuery<
    YoungAdultFileWithObservationSummary[],
    unknown,
    YoungAdultFileWithObservationSummary[]
  >(queryKey, async () => {
    const response = await getAllYoungAdults();
    return response.data;
  });

  return query;
};

export const useYoungAdultFileQuery = (imiloId: string) => {
  const queryKey = getYoungAdultFilesQueryKey.byImiloId(imiloId);
  const query = useQuery<
    YoungAdultFileWithObservationSummary,
    unknown,
    YoungAdultFileWithObservationSummary
  >(queryKey, async () => {
    const response = await getYoungAdult(imiloId);
    return response.data;
  });

  return query;
};
