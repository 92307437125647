import { Stack, StackProps, styled } from '@mui/material';

export const StyledAutonomyProgressionSection = styled(Stack)<StackProps>(({ theme }) => ({
  gap: theme.spacing(10),
  padding: theme.spacing(9),
  flex: 1,
  height: '100%',
}));

export const StyledAutonomyProgressionVisualization = styled(Stack)<StackProps>(() => ({
  margin: 0,
  padding: 0,
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
}));
