import { CardContent, TableHead, TableRow } from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import {
  YoungAdultSorting,
  YoungAdultSortingColumn,
  youngAdultSortingColumnTitle,
} from '~/modules/progressDashboard/domain/sortYoungAdults';
import {
  hasNoYoungAdult,
  useYoungAdultFilesQuery,
} from '~/modules/progressDashboard/infrastructure/useYoungAdultFileQuery';
import { ProgressDashboardTableContent } from '~/modules/progressDashboard/views/ProgressionDashboardCard/ProgressDashboardTableContent';
import { ProgressDashboardTableHeadCell } from '~/modules/progressDashboard/views/ProgressionDashboardCard/ProgressDashboardTableHeadCell';
import { StyledCard } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledCard.style.tsx';
import {
  StyledProgressTable,
  StyledProgressTableContainer,
  StyledProgressTableHeadCell,
} from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledTable.style';

const ACTIONS = 'Actions';

export const ProgressionDashboardCard = () => {
  const cardContentRef = createRef<HTMLDivElement>();
  const [offsetTop, setOffsetTop] = useState(0);
  const { data: youngAdultList, isLoading } = useYoungAdultFilesQuery();
  const [youngAdultSorting, setYoungAdultSorting] = useState<YoungAdultSorting | undefined>(
    undefined
  );

  useEffect(() => {
    const { current } = cardContentRef;
    if (current == null) {
      return;
    }
    setOffsetTop(current.offsetTop);
  }, [cardContentRef]);

  const updateSorting = (column: YoungAdultSortingColumn) => {
    if (column !== youngAdultSorting?.column) {
      setYoungAdultSorting({ column, direction: 'asc' });
      return;
    }
    if (youngAdultSorting.direction === 'asc') {
      setYoungAdultSorting({ column, direction: 'desc' });
      return;
    }
    setYoungAdultSorting(undefined);
  };

  const getDirection = (column: YoungAdultSortingColumn) => {
    if (column !== youngAdultSorting?.column) {
      return undefined;
    }
    return youngAdultSorting.direction;
  };

  return (
    <StyledCard>
      <CardContent>
        <StyledProgressTableContainer ref={cardContentRef} offsetTop={offsetTop || 0}>
          <StyledProgressTable
            fullHeight={isLoading || hasNoYoungAdult(youngAdultList)}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {Object.values(YoungAdultSortingColumn).map((column) => (
                  <ProgressDashboardTableHeadCell
                    key={column}
                    title={youngAdultSortingColumnTitle[column]}
                    direction={getDirection(column)}
                    onClick={() => updateSorting(column)}
                  />
                ))}
                <StyledProgressTableHeadCell>{ACTIONS}</StyledProgressTableHeadCell>
              </TableRow>
            </TableHead>
            <ProgressDashboardTableContent sorting={youngAdultSorting} />
          </StyledProgressTable>
        </StyledProgressTableContainer>
      </CardContent>
    </StyledCard>
  );
};
