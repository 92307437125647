import { useParams } from 'react-router-dom';
import { PROGRESS_DASHBOARD_ROUTE_PATH } from '~/core/router';
import { useAutonomyProgressionQuery } from '~/modules/autonomyProgression/infrastructure/useAutonomyProgressionQuery';
import { SideBar } from '~/modules/observation/components/ObservationNavBar/views/SideBar';
import { SurveySectionContainer } from '~/modules/observation/components/ObservationSection/views/SurveySectionContainer';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { useObservationScores } from '~/modules/observation/domain/useObservationScores';
import { useSectionManager } from '~/modules/observation/domain/useSectionManager';
import { useNavigateOnCondition } from '~/shared/components/hooks/useNavigateOnCondition';
import { theme } from '~/shared/theme/theme';

export const SURVEY_PAGE_TITLE = 'Questionnaire';
export const OBSERVATION_PAGE_LABEL = 'observation-page';
export const SurveyPageContainer = () => {
  const { imiloId: imiloIdParam } = useParams();

  const imiloId = imiloIdParam ?? '';

  const { isError } = useAutonomyProgressionQuery(imiloId);

  const { observationScores, answerDomainQuestion, skipDomainObservation } = useObservationScores();

  const {
    expandNextAccordion,
    goToSection,
    skipSection,
    accordionExpanded,
    handleChange,
    currentSection,
  } = useSectionManager({ confirmSection: () => null });

  const autonomyDomainScores = observationScores[currentSection as ObservationDomain];

  const skipDomainObservationCallback = () => {
    skipDomainObservation(currentSection);
    skipSection(currentSection);
  };

  useNavigateOnCondition(isError === true, PROGRESS_DASHBOARD_ROUTE_PATH);

  // TECHNICAL DEBT: very urgent, very important: use flexbox instead of fixed css (and use styled component)
  return (
    <div
      style={{ padding: theme.spacing(11), paddingTop: theme.spacing(5) }}
      aria-label={OBSERVATION_PAGE_LABEL}
    >
      <SideBar
        goToSection={goToSection}
        currentDomain={currentSection}
        observationScores={observationScores}
      >
        <SurveySectionContainer
          currentSection={currentSection}
          accordionExpanded={accordionExpanded}
          expandNextAccordion={expandNextAccordion}
          handleChange={handleChange}
          autonomyDomainScores={autonomyDomainScores}
          answerDomainQuestion={answerDomainQuestion}
          skipDomainObservationCallback={skipDomainObservationCallback}
          observationScores={observationScores}
          youngAdultFileId={imiloId}
        />
      </SideBar>
    </div>
  );
};
