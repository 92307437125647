import { StyledUnderlinedButton } from '~/shared/components/StyledUnderlinedButton';
export const GENERATE_PDF_BUTTON_LABEL = 'Schéma de pensée';
export const DOWNLOAD_PDF_LINK_LABEL = 'Lien de téléchargement';
export const FILE_PATH = '/schemaDePensee.pdf';

const GENERATE_PDF_BUTTON = 'Schéma de pensée';
const GENERATE_PDF_BUTTON_ICON = '/download_button.svg';
const GENERATE_PDF_BUTTON_ICON_ALT = 'download_button';

export const GeneratePdfButton = () => (
  <StyledUnderlinedButton
    startIcon={<img src={GENERATE_PDF_BUTTON_ICON} alt={GENERATE_PDF_BUTTON_ICON_ALT} />}
    aria-label={GENERATE_PDF_BUTTON_LABEL}
  >
    <a aria-label={DOWNLOAD_PDF_LINK_LABEL} href={FILE_PATH} download>
      {GENERATE_PDF_BUTTON}
    </a>
  </StyledUnderlinedButton>
);
