import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  addYoungAdult,
  AddYoungAdultParams,
  editYoungAdult,
  EditYoungAdultParams,
  followImiloYoungAdult,
  FollowYoungAdultParams,
  unfollowYoungAdult,
  UnfollowYoungAdultParams,
} from '~/modules/progressDashboard/infrastructure/api';
import {
  UserFileInfo,
  YoungAdultFile,
} from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { getYoungAdultFilesQueryKey } from '~/modules/progressDashboard/infrastructure/youngAdultFilesQueryKeys';
import { useMutationWithInvalidation } from '~/shared/reactQuery/reactQueryMutations';

export const useYoungAdultMutations = () => {
  const queryClient = useQueryClient();
  const dependentQueries = [getYoungAdultFilesQueryKey.all()];

  const followImiloYoungAdultMutation = useMutationWithInvalidation<
    { data: YoungAdultFile },
    AxiosError,
    FollowYoungAdultParams
  >({
    mutation: { mutationFn: followImiloYoungAdult },
    queryKeysToInvalidate: dependentQueries,
    queryClient,
  });

  const unfollowYoungAdultMutation = useMutationWithInvalidation<
    null,
    unknown,
    UnfollowYoungAdultParams
  >({
    mutation: { mutationFn: unfollowYoungAdult },
    queryKeysToInvalidate: dependentQueries,
    queryClient,
  });

  const addYoungAdultMutation = useMutationWithInvalidation<
    { data: UserFileInfo },
    AxiosError,
    AddYoungAdultParams
  >({
    mutation: { mutationFn: addYoungAdult },
    queryKeysToInvalidate: dependentQueries,
    queryClient,
  });

  const editYoungAdultMutation = useMutationWithInvalidation<
    { data: UserFileInfo },
    AxiosError,
    EditYoungAdultParams
  >({
    mutation: { mutationFn: editYoungAdult },
    queryKeysToInvalidate: dependentQueries,
    queryClient,
  });

  return {
    addYoungAdultMutation,
    editYoungAdultMutation,
    followImiloYoungAdultMutation,
    unfollowYoungAdultMutation,
  };
};
