import { useMutation } from '@tanstack/react-query';
import {
  postSubmitObservation,
  SubmitObservationParams,
} from '~/modules/observation/components/ObservationSection/infrastructure/api';

export const useObservationMutations = () => {
  const submitObservationMutation = useMutation<null, unknown, SubmitObservationParams>(
    async ({ imiloId, observationScores }: SubmitObservationParams) => {
      const response = await postSubmitObservation({ imiloId, observationScores });
      return response;
    }
  );

  return { submitObservationMutation };
};
