import { useFeatureIsOn } from '@growthbook/growthbook-react';
import InsightsIcon from '@mui/icons-material/Insights';
import { Stack } from '@mui/material';
import { EditYoungAdultButton } from '~/modules/progressDashboard/components/EditButton';
import { UnfollowYoungAdultButton } from '~/modules/progressDashboard/components/UnfollowButton';
import {
  Population,
  UserFileInfo,
} from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { DiagnosisButton } from '~/modules/progressDashboard/views/ProgressionDashboardCard/DiagnosisButton';
import { StyledProgressTableDimmedTypography } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledProgressTableDimmedTypography.style';
import { StyledProgressTableName } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledProgressTableName.style';
import { StyledProgressTableBodyCell } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledTable.style';
import { StyledButton } from '~/shared/components/StyledButton';
import { theme } from '~/shared/theme/theme';

export const EMPTY_YOUNG_LIST = "Vous n'avez pas encore d'usagers en suivi.";
export const YOUNG_ADULT_TABLE_ROW_LABEL = 'user-table-row-label';
export const YOUNG_ADULT_FILE_TABLE_BODY = 'user-file-table-body';

export const DimmedNameCell = ({
  textValue,
  isDimmed,
}: {
  isDimmed: boolean;
  textValue: string;
}) => {
  return (
    <StyledProgressTableBodyCell>
      <StyledProgressTableDimmedTypography dimmed={isDimmed}>
        {textValue}
      </StyledProgressTableDimmedTypography>
    </StyledProgressTableBodyCell>
  );
};

export const NameCell = ({ textValue }: { textValue: string }) => {
  return (
    <StyledProgressTableBodyCell>
      <StyledProgressTableName>{textValue}</StyledProgressTableName>
    </StyledProgressTableBodyCell>
  );
};

export const ActionsCell = ({
  imiloId,
  setLineClickDisabled,
  youngAdult,
}: {
  imiloId: string;
  setLineClickDisabled: (value: boolean) => void;
  youngAdult: UserFileInfo;
}) => {
  const isImiloEnabled = useFeatureIsOn('imilo-connection');
  const isPopulationOne = youngAdult.population === Population.Population1;

  return (
    <StyledProgressTableBodyCell>
      <Stack direction="row" gap={theme.spacing(4)}>
        {!isPopulationOne && (
          <StyledButton variant="contained">
            <InsightsIcon />
          </StyledButton>
        )}
        <DiagnosisButton imiloId={imiloId} />
        <Stack flex={1} direction="row" justifyContent="end">
          {!isImiloEnabled && (
            <EditYoungAdultButton
              setLineClickDisabled={setLineClickDisabled}
              youngAdult={youngAdult}
            />
          )}
          <UnfollowYoungAdultButton imiloId={imiloId} />
        </Stack>
      </Stack>
    </StyledProgressTableBodyCell>
  );
};
