import { Stack, StackProps, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { theme } from '~/shared/theme/theme';

export const StyledConnectionForm = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.secondary.light,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(9),
}));

export const StyledForm: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
};
