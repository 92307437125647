import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getAutonomyProgressionData } from '~/modules/autonomyProgression/infrastructure/api';
import { getAutonomyProgressionQueryKey } from '~/modules/autonomyProgression/infrastructure/autonomyProgressionDataQueryKeys';
import { AutonomyData } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';

export const useAutonomyProgressionQuery = (imiloId: string) => {
  const queryKey = getAutonomyProgressionQueryKey.byImiloId(imiloId);

  const query = useQuery<AutonomyData, AxiosError, AutonomyData>(queryKey, () =>
    getAutonomyProgressionData({ imiloId })
  );

  return query;
};
