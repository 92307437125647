import { Stack, StackProps, styled } from '@mui/material';

export const StyledSidebar = styled(Stack)<StackProps>(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.secondary.light,
  alignItems: 'start',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '360px',
}));
