import {
  StyledAccordion,
  StyledAccordionDetails,
} from '~/modules/observation/components/ObservationSection/views/QuestionAccordion.style';
import { QuestionCard } from '~/modules/observation/components/ObservationSection/views/QuestionCard';
import { QuestionSummary } from '~/modules/observation/components/ObservationSection/views/QuestionSummary';

interface QuestionAccordionProps {
  questionIndex: number;
  title: string;
  description: string;
  expanded: boolean;
  selected?: number;
  onSelect: (index: number) => () => void;
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

export const QuestionAccordion = ({
  questionIndex,
  title,
  description,
  expanded,
  selected,
  onSelect,
  handleChange,
}: QuestionAccordionProps) => {
  return (
    <StyledAccordion onChange={handleChange} expanded={expanded}>
      <QuestionSummary index={questionIndex} title={title} disabled={!expanded} />
      <StyledAccordionDetails>
        <QuestionCard description={description} onSelect={onSelect} selected={selected} />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
