import { CircularProgress, Stack, Typography } from '@mui/material';
import { StyledYoungAdultProgressionInfoCard } from '~/modules/autonomyProgression/views/YoungAdultInfoCard.style';
import { YoungAdultInfo } from '~/modules/observation/components/ObservationNavBar/domain/extractYoungAdultInfo';
import { ProgressionSurveyStep } from '~/modules/observation/components/ObservationNavBar/views/ProgressionSurveyStep';
import {
  ObservationDomain,
  observationDomains,
} from '~/modules/observation/domain/observationDomains';
import { theme } from '~/shared/theme/theme';
import { ValuesType } from '~/shared/types';

export const YOUNG_ADULT_PROGRESSION_INFO_LABEL = 'young-adult-progression-info';
const DIAGNOSES_HISTORY = 'Historique des diagnostics';

const ProgressionInfo = {
  Id: 'Identifiant',
  NationalSupportPlan: 'Dispositif national',
  Age: 'Âge',
  TotalDiagnoses: 'Nombre total',
  FirstDiagnosisDate: 'Date premier',
  LastDiagnosisDate: 'Date dernier',
  TimeSinceLastDiagnosis: 'Délai depuis dernier',
};

type ProgressionInfo = ValuesType<typeof ProgressionInfo>;

export interface YoungAdultProgressionInfoCardProps {
  youngAdultInfo?: YoungAdultInfo;
  isLoading?: boolean;
  selectedDomain: ObservationDomain;
  selectDomain: (domain: ObservationDomain) => void;
}

export const YoungAdultProgressionInfoCard = ({
  isLoading,
  youngAdultInfo,
  selectedDomain,
  selectDomain,
}: YoungAdultProgressionInfoCardProps) => {
  if (youngAdultInfo === undefined || isLoading === true)
    return (
      <StyledYoungAdultProgressionInfoCard
        alignItems="center"
        aria-label={YOUNG_ADULT_PROGRESSION_INFO_LABEL}
      >
        <CircularProgress />
      </StyledYoungAdultProgressionInfoCard>
    );

  return (
    <StyledYoungAdultProgressionInfoCard aria-label={YOUNG_ADULT_PROGRESSION_INFO_LABEL}>
      <Typography variant="h5" gutterBottom>
        {youngAdultInfo.name}
      </Typography>
      <InfoRow name={ProgressionInfo.Id} value={youngAdultInfo.fileId} />
      <InfoRow
        name={ProgressionInfo.NationalSupportPlan}
        value={youngAdultInfo.nationalSupportPlan}
      />
      <InfoRow name={ProgressionInfo.Age} value={youngAdultInfo.age} />
      <Typography variant="subtitle1" marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
        {DIAGNOSES_HISTORY}
      </Typography>
      <InfoRow
        name={ProgressionInfo.TotalDiagnoses}
        value={youngAdultInfo.submittedObservationsCount}
        variant="bold"
      />
      <InfoRow
        name={ProgressionInfo.FirstDiagnosisDate}
        value={youngAdultInfo.firstObservationDate}
        variant="bold"
      />
      <InfoRow
        name={ProgressionInfo.LastDiagnosisDate}
        value={youngAdultInfo.lastObservationDate}
        variant="bold"
      />
      <InfoRow
        name={ProgressionInfo.TimeSinceLastDiagnosis}
        value={youngAdultInfo.timeSinceLastObservationDate}
        variant="bold"
      />
      <Stack paddingTop={theme.spacing(3)}>
        {observationDomains.map((domain) => (
          <ProgressionSurveyStep
            domain={domain}
            goToSection={(section) => selectDomain(section)}
            focused={domain === selectedDomain}
            key={domain}
          />
        ))}
      </Stack>
    </StyledYoungAdultProgressionInfoCard>
  );
};

const InfoRow = ({
  name,
  value,
  variant,
}: {
  name: ProgressionInfo;
  value: string;
  variant?: 'grey' | 'bold';
}) => {
  const infoLabelProps =
    variant === 'bold'
      ? { fontWeight: theme.typography.fontWeightBold }
      : { color: theme.palette.grey['300'] };

  return (
    <Stack direction="row" gap={theme.spacing(2)}>
      <Typography variant="body1" flex="none" {...infoLabelProps}>
        {`${name} :`}
      </Typography>
      <Typography variant="body1" noWrap>
        {value}
      </Typography>
    </Stack>
  );
};
