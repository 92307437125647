import {
  firstAccordion,
  Question,
  secondAccordion,
  thirdAccordion,
} from '~/modules/observation/components/ObservationSection/domain/surveySections';

export const sectionQuestions: Question[] = [
  {
    title: 'Capacité à être accompagné',
    description:
      "Il s'agit de la capacité de l'usager à s'adresser aux acteurs utiles à son parcours et/ou s'orienter vers de nouveaux intervenants.",
    id: firstAccordion,
  },
  {
    title: 'Capacité à outiller son parcours',
    description:
      "Il s'agit de la capacité de l'usager à s'emparer des outils et des dispositifs d'accompagnement utiles à son parcours.",
    id: secondAccordion,
  },
  {
    title: 'Capacité à adopter les comportements adéquats',
    description:
      "Il s'agit de la capacité de l'usager à adopter les comportements et savoir-être utiles à son parcours.",
    id: thirdAccordion,
  },
];
