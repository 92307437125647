import { useMutation } from '@tanstack/react-query';
import { deleteYoungAdult, DeleteYoungAdultParams } from '~/modules/admin/infrastructure/api';

export const useAdminMutations = () => {
  const deleteYoungAdultMutation = useMutation<void, unknown, DeleteYoungAdultParams>(
    deleteYoungAdult
  );

  return { deleteYoungAdultMutation };
};
