import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useEffect } from 'react';

const GROWTHBOOK_API_URL = 'https://cdn.growthbook.io';

const growthBook = new GrowthBook({
  apiHost: GROWTHBOOK_API_URL,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: import.meta.env.VITE_APP_ENV_NAME !== 'production',
});

export const FeatureFlagsProvider = ({ children }: { children?: JSX.Element }): JSX.Element => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    void growthBook.init({ streaming: true });
  }, []);

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
};
