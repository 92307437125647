import { createTheme, PaletteColorOptions } from '@mui/material';

export const theme = createTheme({
  palette: {
    // see https://www.figma.com/file/p66aqjTY2t82cp0fNNGqql/Lot-1-Chugai-(Haigo)?node-id=2%3A3
    primary: {
      main: '#A3195B',
      light: '#F6C5DD',
      dark: '#E55398',
    },
    secondary: {
      main: '#2897D5',
      light: '#EEF7FC',
      dark: '#BADEF2',
    },
    error: {
      main: '#D60B52',
      light: '#FBB1CB',
    },
    success: {
      main: '#95C11F',
      light: '#E4F4B9',
    },
    warning: {
      main: '#FF9500',
      light: '#FFE7C2',
    },
    grey: {
      50: '#F1F3F3',
      100: '#D6DADC',
      200: '#B2B9BD',
      300: '#838F95',
      400: '#AEAEB2',
      500: '#C7C7CC',
      600: '#E5E5EA',
      700: '#F8FAFC',
    },
    pink: {
      main: '#A3195B',
      light: '#F6C5DD',
    },
    blue: {
      '500': '#2897D5',
      '400': '#BADEF2',
      '100': '#EEF7FC',
    },
    chartColor1: {
      main: '#009FE3',
    },
    chartColor2: {
      main: '#95C11F',
    },
    chartColor3: {
      main: '#D60B52',
    },
    text: {
      primary: '#040F15',
      disabled: '#838F95',
    },
  },
  typography: {
    fontFamily: ['Mulish', 'Poppins', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 200,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: 46,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    h2: {
      fontSize: 36,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    h3: {
      fontSize: 32,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    h4: {
      fontSize: 30,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    h5: {
      fontSize: 26,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    h6: {
      fontSize: 22,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
      color: '#040F15',
      lineHeight: '130%',
      fontWeight: 900,
    },
    body1: {
      fontFamily: 'Arial',
      fontSize: 16,
      color: '#040F15',
      lineHeight: '150%',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Arial',
      fontSize: 14,
      color: '#040F15',
      lineHeight: '150%',
      fontWeight: 400,
    },
    caption: {
      fontFamily: 'Poppins',
      fontSize: 12,
      color: '#040F15',
      lineHeight: '150%',
      fontWeight: 600,
    },
    button: {
      fontFamily: 'Mulish',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '150%',
    },
    link: {
      fontFamily: 'Mulish',
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
      color: '#A3195B',
      textDecoration: 'underline',
    },
    label: {
      color: '#859FBB',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '13px',
    },
    surveyCardDescription: {
      fontFamily: 'Arial',
      fontSize: 16,
      fontWeight: 400,
      color: '#040F15',
      lineHeight: '150%',
      display: 'block',
    },
  },
  spacing: [4, 8, 12, 14, 16, 20, 24, 27, 29, 32, 40, 60, 70, 90],
  shape: {
    borderRadius: 8,
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    pink: Palette['primary'] & Partial<PaletteColorOptions>;
    blue: Palette['primary'] & Partial<PaletteColorOptions>;
    chartColor1: Palette['primary'];
    chartColor2: Palette['primary'];
    chartColor3: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    pink?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    chartColor1?: PaletteOptions['primary'];
    chartColor2?: PaletteOptions['primary'];
    chartColor3?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    label: React.CSSProperties;
    expandedAccordion: React.CSSProperties;
    collapsedAccordion: React.CSSProperties;
    surveyCardDescription: React.CSSProperties;
    link: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    surveyCardDescription: React.CSSProperties;
    link: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    surveyCardDescription: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}
