import { ReactKeycloakProvider } from '@react-keycloak/web';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import keycloak from './Keycloak';

export const USER_IDTOKEN_COOKIE_KEY = 'userIdToken';

export type AuthContextProps = {
  email: string | null;
  login: (idToken: string) => void;
  logout: () => void;
};

// Not supposed to be accessed outside (exported for test mocking purposes)
export const _AuthContext = createContext<AuthContextProps>({
  email: null,
  login: () => void 0,
  logout: () => void 0,
});

function extractEmailFromJwt(jwt: string) {
  const decodedToken = jwt_decode(jwt) as { email: string };
  const email = decodedToken?.['email'];
  return email;
}

export const useAuth = () => useContext(_AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const previouslyStoredIdToken = Cookies.get(USER_IDTOKEN_COOKIE_KEY);

  const [idToken, setIdToken] = useState<string | null>(previouslyStoredIdToken ?? null);

  const email = idToken !== null ? extractEmailFromJwt(idToken) : null;

  useEffect(() => {
    if (idToken !== null) {
      Cookies.set(USER_IDTOKEN_COOKIE_KEY, idToken);
    } else {
      Cookies.remove(USER_IDTOKEN_COOKIE_KEY);
    }
  }, [idToken]);

  const login = (idToken: string) => {
    setIdToken(idToken);
  };

  const logout = () => {
    setIdToken(null);
    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
        prompt: 'login',
        onLoad: 'check-sso',
        flow: 'implicit',
      }}
      onTokens={(e) => {
        if (typeof e.idToken === 'string') {
          login(e.idToken);
        }
      }}
    >
      <_AuthContext.Provider value={{ email, login, logout }}>{children}</_AuthContext.Provider>
    </ReactKeycloakProvider>
  );
};
