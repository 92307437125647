import { useState } from 'react';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { domainQuestions } from '~/modules/observation/domain/observationQuestions';
import {
  getInitialObservationScores,
  ObservationScores,
  skippedObservationScore,
} from '~/modules/observation/domain/observationScores';

export const useObservationScores = () => {
  const [observationScores, setObservationScores] = useState<ObservationScores>(
    getInitialObservationScores({})
  );

  const answerDomainQuestion = (
    domain: ObservationDomain,
    questionIndex: number,
    score: number
  ) => {
    if (questionIndex >= domainQuestions.length) return;

    const newAutonomyScores = observationScores[domain].slice(0);
    newAutonomyScores.splice(questionIndex, 1, score);
    setObservationScores({ ...observationScores, [domain]: newAutonomyScores });
  };

  const skipDomainObservation = (domain: ObservationDomain) => {
    setObservationScores({ ...observationScores, [domain]: skippedObservationScore });
  };

  return {
    observationScores,
    answerDomainQuestion,
    skipDomainObservation,
  };
};
