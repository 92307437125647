import { theme } from '~/shared/theme/theme';

const FEEDBACK_BUTTON_IMG_PATH = 'https://jedonnemonavis.numerique.gouv.fr/static/bouton-bleu.svg';
export const FEEDBACK_BUTTON_LABEL = 'Je donne mon avis';
const FEEDBACK_BUTTON_ROUTE = 'https://jedonnemonavis.numerique.gouv.fr/Demarches/3185?button=3402';

export const FeedbackButton = () => (
  <a
    href={FEEDBACK_BUTTON_ROUTE}
    style={{
      bottom: theme.spacing(1),
      cursor: 'pointer',
      position: 'fixed',
      right: theme.spacing(1),
    }}
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={FEEDBACK_BUTTON_IMG_PATH}
      aria-label={FEEDBACK_BUTTON_LABEL}
      alt={FEEDBACK_BUTTON_LABEL}
      height="36px"
    />
  </a>
);
