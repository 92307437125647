import { styled } from '@mui/material';

export const StyledAdminConnexionPage = styled('div')(() => ({
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledAdminDashboardPage = styled('div')(() => ({
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
