import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateOnCondition = (condition: boolean, destinationRoute: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (condition === true) {
      navigate(destinationRoute);
    }
  }, [condition, navigate, destinationRoute]);
};
