import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import { useYoungAdultMutations } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileMutations';
import { StyledButtonWithTooltip } from '~/shared/components/StyledButtonWithTooltip';
import { theme } from '~/shared/theme/theme';

export const UNFOLLOW_BUTTON_LABEL_PREFIX = 'unfollow-button';
export const UNFOLLOW_BUTTON_TOOLTIP_TEXT = 'Ne plus suivre cet usager';

export const UnfollowYoungAdultButton = ({ imiloId }: { imiloId: string }) => {
  const { unfollowYoungAdultMutation } = useYoungAdultMutations();

  const unfollowYoungAdult: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event?.stopPropagation();
    unfollowYoungAdultMutation.mutate({ imiloId });
  };

  return (
    <StyledButtonWithTooltip
      onClick={unfollowYoungAdult}
      loading={unfollowYoungAdultMutation.isLoading}
      aria-label={`${UNFOLLOW_BUTTON_LABEL_PREFIX}-${imiloId}`}
      color="primary"
      variant="secondary"
      sx={{ marginLeft: theme.spacing(8), color: theme.palette.primary.main }}
      tooltipProps={{ title: UNFOLLOW_BUTTON_TOOLTIP_TEXT, placement: 'top', arrow: true }}
    >
      <BackspaceRoundedIcon
        sx={{
          color: theme.palette.grey['300'],
          visibility: unfollowYoungAdultMutation.isLoading ? 'hidden' : 'inherit',
        }}
      />
    </StyledButtonWithTooltip>
  );
};
