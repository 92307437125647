import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  styled,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  marginBottom: theme.spacing(6),
  boxShadow: '0px 0px 4px rgba(32, 122, 172, 0.3), 2px 4px 16px rgba(32, 121, 172, 0.1)',
  borderRadius: 10,
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderRadius: 10,
    marginBottom: theme.spacing(10),
  },
  '&:first-of-type': {
    borderRadius: 10,
  },
  '.Mui-expanded&:last-of-type': {
    marginBottom: theme.spacing(10),
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(() => ({
  padding: 35,
  paddingTop: 10,
}));
