import { YoungAdultFileWithObservationSummary } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';

export type SortingDirection = 'asc' | 'desc';

export const YoungAdultSortingColumn = {
  FirstName: 'FIRSTNAME',
  LastName: 'LASTNAME',
  Population: 'POPULATION',
  NationalSupport: 'NATIONAL_SUPPORT',
  FirstDiagnosis: 'FIRST_DIAGNOSIS',
  LastDiagnosis: 'LAST_DIAGNOSIS',
} as const;
export type YoungAdultSortingColumn =
  typeof YoungAdultSortingColumn[keyof typeof YoungAdultSortingColumn];

export const youngAdultSortingColumnTitle: Record<YoungAdultSortingColumn, string> = {
  [YoungAdultSortingColumn.FirstName]: 'Prénom',
  [YoungAdultSortingColumn.LastName]: 'Nom',
  [YoungAdultSortingColumn.Population]: 'Population',
  [YoungAdultSortingColumn.NationalSupport]: 'Dispositif national',
  [YoungAdultSortingColumn.FirstDiagnosis]: 'Début du diagnostic',
  [YoungAdultSortingColumn.LastDiagnosis]: 'Dernier diagnostic',
};

export type YoungAdultSorting = {
  column: YoungAdultSortingColumn;
  direction: SortingDirection;
};

export const sortYoungAdults = (
  youngAdultList: YoungAdultFileWithObservationSummary[],
  sorting: YoungAdultSorting | undefined
) => {
  if (sorting === undefined) {
    return youngAdultList;
  }

  const directionFactor = sorting.direction == 'asc' ? 1 : -1;
  return youngAdultList
    .slice()
    .sort(
      (youngAdultA, youngAdultB) =>
        directionFactor * sortTwoYoungAdultsAscending(youngAdultA, youngAdultB, sorting.column)
    );
};

const sortTwoYoungAdultsAscending = (
  youngAdultA: YoungAdultFileWithObservationSummary,
  youngAdultB: YoungAdultFileWithObservationSummary,
  column: YoungAdultSortingColumn
) => {
  switch (column) {
    case YoungAdultSortingColumn.FirstName:
      return sortByStringAscending(youngAdultA.firstname, youngAdultB.firstname);
    case YoungAdultSortingColumn.LastName:
      return sortByStringAscending(youngAdultA.lastname, youngAdultB.lastname);
    case YoungAdultSortingColumn.Population:
      return sortByStringAscending(youngAdultA.population, youngAdultB.population);
    case YoungAdultSortingColumn.NationalSupport:
      return sortByStringAscending(
        youngAdultA.nationalSupportPlan,
        youngAdultB.nationalSupportPlan
      );
    case YoungAdultSortingColumn.FirstDiagnosis:
      return sortByDateAscending(
        youngAdultA.firstObservationDate,
        youngAdultA.firstObservationDate
      );
    case YoungAdultSortingColumn.LastDiagnosis:
      return sortByDateAscending(youngAdultA.lastObservationDate, youngAdultB.lastObservationDate);
    default:
      return -1;
  }
};

const sortByStringAscending = (
  stringA: string | undefined | null,
  stringB: string | undefined | null
) => {
  if (stringA === undefined || stringA === null) {
    return -1;
  }

  if (stringB === undefined || stringB === null) {
    return 1;
  }

  return stringA.toLowerCase() < stringB.toLowerCase() ? -1 : 1;
};

const sortByDateAscending = (stringA: string | null, stringB: string | null) => {
  if (stringA === null) {
    return -1;
  }

  if (stringB === null) {
    return 1;
  }

  const dateA = new Date(stringA);
  const dateB = new Date(stringB);

  return dateA < dateB ? -1 : 1;
};
