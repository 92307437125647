import { TableSortLabel } from '@mui/material';
import { SortingDirection } from '~/modules/progressDashboard/domain/sortYoungAdults';
import { StyledProgressTableHeadCell } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledTable.style';

type Props = {
  title: string;
  onClick: () => void;
  direction: SortingDirection | undefined;
};

export const ProgressDashboardTableHeadCell = ({
  title,
  onClick,
  direction = undefined,
}: Props) => {
  return (
    <StyledProgressTableHeadCell>
      <TableSortLabel active={direction !== undefined} direction={direction} onClick={onClick}>
        {title}
      </TableSortLabel>
    </StyledProgressTableHeadCell>
  );
};
