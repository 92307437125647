import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { SURVEY_ROUTE_PATH } from '~/core/router';
import { StyledAutonomyProgressionEmptyContainer } from '~/modules/autonomyProgression/views/AutonomyProgressionEmpty.style';
import { StyledButton } from '~/shared/components/StyledButton';

export const ADD_DIAGNOSIS_LABEL = "Il n'y a pas encore de diagnostics associés à cet usager.";
export const ADD_DIAGNOSIS_BUTTON = 'Ajouter un diagnostic';

export const AutonomyProgressionEmpty = () => {
  const navigate = useNavigate();
  const { imiloId } = useParams();

  return (
    <StyledAutonomyProgressionEmptyContainer>
      <Typography variant="body1">{ADD_DIAGNOSIS_LABEL}</Typography>
      <StyledButton
        type="submit"
        color="primary"
        variant="contained"
        aria-label={ADD_DIAGNOSIS_BUTTON}
        endIcon={<AddIcon />}
        onClick={() => {
          navigate(`${SURVEY_ROUTE_PATH}/${imiloId}`);
        }}
      >
        {ADD_DIAGNOSIS_BUTTON}
      </StyledButton>
    </StyledAutonomyProgressionEmptyContainer>
  );
};
