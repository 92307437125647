import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/core/auth/AuthContext';
import {
  StyledAvatar,
  StyledDivider,
  StyledHeader,
  StyledHeaderContent,
  StyledHeaderUserInfo,
} from '~/core/components/header/Header.style';
import { PROGRESS_DASHBOARD_ROUTE_PATH } from '~/core/router';
import { StyledUnderlinedButton } from '~/shared/components/StyledUnderlinedButton';
import { theme } from '~/shared/theme/theme';

export const GO_BACK_HOME_BUTTON_LABEL = 'return to Dashboard Progress';
export const GO_BACK_HOME_BUTTON_TEXT = 'Tableau des progrès';
export const HEADER_LOGO_LABEL = 'MILOrizons logo';
export const HORIZONTAL_LOGO_ROUTE_PATH = '/MILOrizons_Horizontal_Logo.svg';
export const HEADER_LABEL = 'MILOrizons Header';
export const LOGOUT_BUTTON_LABEL = 'logout-button';

export const Header = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  const logoutAndNavigateToConnectionPage = () => {
    auth.logout();
  };

  return (
    <StyledHeader aria-label={HEADER_LABEL}>
      <StyledHeaderContent>
        <img
          src={HORIZONTAL_LOGO_ROUTE_PATH}
          aria-label={HEADER_LOGO_LABEL}
          alt={HEADER_LOGO_LABEL}
          height="32px"
        />
        <StyledUnderlinedButton
          onClick={() => navigate(PROGRESS_DASHBOARD_ROUTE_PATH)}
          aria-label={GO_BACK_HOME_BUTTON_LABEL}
        >
          {GO_BACK_HOME_BUTTON_TEXT}
        </StyledUnderlinedButton>
        <StyledHeaderUserInfo>
          <Typography variant="body2" color={theme.palette.grey['300']}>
            {auth.email}
          </Typography>
          <StyledAvatar />
          <IconButton
            color="primary"
            size="medium"
            aria-label={LOGOUT_BUTTON_LABEL}
            onClick={logoutAndNavigateToConnectionPage}
          >
            <PowerSettingsNewOutlinedIcon />
          </IconButton>
        </StyledHeaderUserInfo>
      </StyledHeaderContent>
      <StyledDivider />
    </StyledHeader>
  );
};
