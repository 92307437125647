import { MutationOptions, QueryClient, QueryKey, useMutation } from '@tanstack/react-query';

export type UseMutationWithInvalidationProps<TData, TError, TVariables, TContext> = {
  mutation: MutationOptions<TData, TError, TVariables, TContext>;
  queryKeysToInvalidate: QueryKey[];
  queryClient: QueryClient;
};
export const useMutationWithInvalidation = <
  TData,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>({
  mutation,
  queryKeysToInvalidate,
  queryClient,
}: UseMutationWithInvalidationProps<TData, TError, TVariables, TContext>) => {
  return useMutation<TData, TError, TVariables, TContext>({
    ...mutation,
    onSettled: (data, error, variables, context) => {
      queryKeysToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
      return mutation.onSettled?.(data, error, variables, context);
    },
  });
};
