import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {
  AdminAuthService,
  AdminConnexionStatus,
} from '~/modules/admin/infrastructure/adminAuthService';

class AuthSingleton {
  private static instance: AuthSingleton | undefined;
  app: FirebaseApp | undefined;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getAuth() {
    return getAuth(this.getInstance().app);
  }

  private static getInstance() {
    if (this.instance === undefined) {
      const newInstance = new AuthSingleton();
      newInstance.app = this.init();
      this.instance = newInstance;
      return newInstance;
    } else {
      return this.instance;
    }
  }

  private static init() {
    const firebaseOptions: FirebaseOptions = {
      apiKey: 'AIzaSyDo6nzW13PBZxpahC0765sX3Z-ZF22vq38',
      authDomain: 'milorizons.firebaseapp.com',
      projectId: 'milorizons',
      storageBucket: 'milorizons.appspot.com',
      messagingSenderId: '734332481687',
      appId: '1:734332481687:web:0c2f4721e37eeda4c5b9d2',
    };
    return initializeApp(firebaseOptions);
  }
}

const signInAdminUser = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(AuthSingleton.getAuth(), email, password);
    return true;
  } catch (e) {
    return false;
  }
};

const signOutAdminUser = async () => {
  await AuthSingleton.getAuth().signOut();
};

const useAdminConnexionStatus = () => {
  const [connexionStatus, setConnexionStatus] = useState<AdminConnexionStatus>({
    status: 'LOADING',
  });

  useEffect(
    () =>
      AuthSingleton.getAuth().onAuthStateChanged(async (user) => {
        if (user === null) {
          setConnexionStatus({ status: 'NOT CONNECTED' });
          return;
        } else {
          const jwt = await user.getIdToken();
          setConnexionStatus({ status: 'CONNECTED', jwt });
          return;
        }
      }),
    []
  );

  return connexionStatus;
};

export const firebaseAuthService: AdminAuthService = {
  signInAdminUser,
  signOutAdminUser,
  useAdminConnexionStatus,
};
