import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_CONNEXION_PAGE_ROUTE_PATH } from '~/core/router';
import { AdminAuthService } from '~/modules/admin/infrastructure/adminAuthService';
import { firebaseAuthService } from '~/modules/admin/infrastructure/firebaseAdminAuthService';
import { useAdminMutations } from '~/modules/admin/infrastructure/useAdminMutations';
import { StyledAdminDashboardPage } from '~/modules/admin/views/AdminPage.styled';
import { ConfirmYoungAdultDeletionDialog } from '~/modules/admin/views/ConfirmYoungAdultDeletionDialog';
import { StyledButton } from '~/shared/components/StyledButton';
import { StyledTextField } from '~/shared/components/StyledTextfield';
import { theme } from '~/shared/theme/theme';

const PAGE_TITLE = 'Dashboard administrateur';
export const TEXT_FIELD_YOUNG_ADULT_ID_TITLE = "Identifiant de l'usager";
const TEXT_FIELD_YOUNG_ADULT_ID_PLACEHOLDER = '6269900';
export const BUTTON_DELETE_YOUNG_ADULT = 'Supprimer cet usager';
export const BUTTON_DISCONNECTION_TITLE = 'Déconnexion';

export const DELETION_RESPONSE_SUCCESS = "L'usager a bien été supprimé";
const DELETION_RESPONSE_YOUNG_ADULT_NOT_FOUND = "Aucun usager avec cet identifiant n'a été trouvé";
const DELETION_RESPONSE_NOT_AN_ADMIN = "Ce compte n'est pas un compte administrateur";
const DELETION_RESPONSE_UNKNOWN = 'Erreur inconnue';

type Props = {
  adminAuthService?: AdminAuthService;
};

export const AdminDashboardPage = ({ adminAuthService = firebaseAuthService }: Props) => {
  const { status: adminConnexionStatus, jwt } = adminAuthService.useAdminConnexionStatus();
  const navigate = useNavigate();
  const [youngAdultId, setYoungAdultId] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { deleteYoungAdultMutation } = useAdminMutations();
  const deletionResponseMessage = getErrorMessage({
    isSuccess: deleteYoungAdultMutation.isSuccess,
    error: deleteYoungAdultMutation.error,
  });

  useEffect(() => {
    if (adminConnexionStatus === 'NOT CONNECTED') {
      navigate(ADMIN_CONNEXION_PAGE_ROUTE_PATH);
    }
  });

  if (adminConnexionStatus !== 'CONNECTED') {
    return null;
  }

  return (
    <StyledAdminDashboardPage>
      <Typography variant="h5" paddingBottom={theme.spacing(7)}>
        {PAGE_TITLE}
      </Typography>
      <Stack direction="column" component={'form'} width={300} gap={theme.spacing(7)}>
        <StyledTextField
          value={youngAdultId}
          label={TEXT_FIELD_YOUNG_ADULT_ID_TITLE}
          size="small"
          inputProps={{
            'aria-label': TEXT_FIELD_YOUNG_ADULT_ID_TITLE,
          }}
          onChange={(e) => setYoungAdultId(e.target.value)}
          placeholder={TEXT_FIELD_YOUNG_ADULT_ID_PLACEHOLDER}
        />
        <StyledButton color="primary" variant="contained" onClick={() => setIsDialogOpen(true)}>
          {BUTTON_DELETE_YOUNG_ADULT}
        </StyledButton>
        <StyledButton
          color="primary"
          variant="outlined"
          onClick={async () => {
            await adminAuthService.signOutAdminUser();
          }}
        >
          {BUTTON_DISCONNECTION_TITLE}
        </StyledButton>
      </Stack>
      <ConfirmYoungAdultDeletionDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onYesClicked={() => {
          deleteYoungAdultMutation.mutateAsync({ imiloId: youngAdultId, adminJwt: jwt });
          setIsDialogOpen(false);
        }}
        onNoClicked={() => setIsDialogOpen(false)}
      />
      <Snackbar
        open={deletionResponseMessage !== undefined}
        onClose={() => deleteYoungAdultMutation.reset()}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={deleteYoungAdultMutation.isSuccess ? 'success' : 'error'} variant="filled">
          {deletionResponseMessage}
        </Alert>
      </Snackbar>
    </StyledAdminDashboardPage>
  );
};

const getErrorMessage = ({ isSuccess, error }: { isSuccess: boolean; error: unknown }) => {
  if (isSuccess) {
    return DELETION_RESPONSE_SUCCESS;
  }

  if (error === null) {
    return;
  }

  // eslint-disable-next-line import/no-named-as-default-member
  if (!axios.isAxiosError(error)) {
    return DELETION_RESPONSE_UNKNOWN;
  }

  switch (error.response?.status) {
    case 403:
      return DELETION_RESPONSE_NOT_AN_ADMIN;
    case 404:
      return DELETION_RESPONSE_YOUNG_ADULT_NOT_FOUND;
    default:
      return DELETION_RESPONSE_UNKNOWN;
  }
};
