import { ObservationScores } from '~/modules/observation/domain/observationScores';
import { ObservationStatus } from '~/modules/observation/domain/observationStatuses';

export class SubmitObservationDTO {
  status!: ObservationStatus;
  employmentAccessAutonomy!: (number | null)[];
  trainingAutonomy!: (number | null)[];
  mobilityAutonomy!: (number | null)[];
  housingAutonomy!: (number | null)[];
  healthAutonomy!: (number | null)[];
  citizenshipAutonomy!: (number | null)[];
  financialAutonomy!: (number | null)[];
}

export const getSubmitObservationDtoFromObservationScores = (
  observationScore: ObservationScores,
  status: ObservationStatus
) => {
  const dto: SubmitObservationDTO = {
    status,
    employmentAccessAutonomy: observationScore.employmentAccess,
    trainingAutonomy: observationScore.training,
    mobilityAutonomy: observationScore.mobility,
    housingAutonomy: observationScore.housing,
    healthAutonomy: observationScore.health,
    citizenshipAutonomy: observationScore.citizenship,
    financialAutonomy: observationScore.financial,
  };

  return dto;
};
