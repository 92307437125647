import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  height: 40,
  '&::placeholder': {
    color: theme.palette.grey[300],
  },
  [`& fieldset`]: {
    borderRadius: 8,
    borderColor: `${theme.palette.grey[300]}`,
  },
}));
