import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { sectionQuestions } from '~/modules/observation/components/ObservationSection/domain/sectionQuestions';
import {
  AccordionExpanded,
  SurveySection,
} from '~/modules/observation/components/ObservationSection/domain/surveySections';
import { useObservationMutations } from '~/modules/observation/components/ObservationSection/infrastructure/useObservationMutations';
import { ObservationSectionTitle } from '~/modules/observation/components/ObservationSection/views/ObservationSectionTitle';
import { QuestionAccordion } from '~/modules/observation/components/ObservationSection/views/QuestionAccordion';
import { SkipDomainObservationButton } from '~/modules/observation/components/ObservationSection/views/SkipDomainObservationButton';
import { SubmissionErrorSnackbar } from '~/modules/observation/components/ObservationSection/views/Submission/SubmissionErrorSnackbar';
import { SubmissionSuccessDialog } from '~/modules/observation/components/ObservationSection/views/Submission/SubmissionSuccessDialog';
import { SubmitDiagnosisButton } from '~/modules/observation/components/ObservationSection/views/Submission/SubmitDiagnosisButton';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import {
  AutonomyScore,
  isInitialAutonomyScore,
  ObservationScores,
} from '~/modules/observation/domain/observationScores';
import { HandleChange } from '~/modules/observation/domain/useSectionManager';
import { usePageLeavePrompt } from '~/shared/components/hooks/useConfirmPageLeave';
import { theme } from '~/shared/theme/theme';

export const UNSAVED_CHANGES_WARNING_MSG =
  '\nLes changements non sauvegardés ne seront pas enregistrés.\n\nQuitter la page quand même ?';

interface SurveySectionContainerProps {
  currentSection: SurveySection;
  accordionExpanded: false | AccordionExpanded;
  expandNextAccordion: () => void;
  handleChange: HandleChange;
  autonomyDomainScores: AutonomyScore;
  answerDomainQuestion: (domain: ObservationDomain, questionIndex: number, score: number) => void;
  skipDomainObservationCallback: () => void;
  youngAdultFileId: string;
  observationScores: ObservationScores;
}
export const SurveySectionContainer = ({
  currentSection,
  accordionExpanded,
  expandNextAccordion,
  handleChange,
  autonomyDomainScores,
  answerDomainQuestion,
  skipDomainObservationCallback,
  youngAdultFileId,
  observationScores,
}: SurveySectionContainerProps) => {
  const [isSuccessDialogVisible, showSuccessDialog] = useState(false);
  const [isErrorSnackbarVisible, showErrorSnackbar] = useState(false);

  const onResponseSelect = (questionIndex: number) => (buttonIndex: number) => {
    return () => {
      const questionScore = buttonIndex + 1;
      answerDomainQuestion(currentSection, questionIndex, questionScore);
      return setTimeout(expandNextAccordion, 500);
    };
  };

  const getSelectedButtonIndexFromScore = (score: number | null) => {
    if (score !== null && score > 0) return score - 1;
    return undefined;
  };

  const { submitObservationMutation } = useObservationMutations();

  const submitObservation = async () => {
    submitObservationMutation.mutate(
      { imiloId: youngAdultFileId, observationScores: observationScores },
      {
        onSuccess: () => showSuccessDialog(true),
        onError: () => showErrorSnackbar(true),
      }
    );
  };

  const isObservationPartiallyFilled = !Object.values(observationScores).every((score) =>
    isInitialAutonomyScore(score)
  );
  usePageLeavePrompt(
    UNSAVED_CHANGES_WARNING_MSG,
    isObservationPartiallyFilled && !isSuccessDialogVisible
  );

  // TECHNICAL DEBT: urgent, very important: use styled component
  return (
    <Grid container direction={'column'} rowSpacing={theme.spacing(9)}>
      <Grid item xs={6}>
        <ObservationSectionTitle currentSection={currentSection} />
      </Grid>
      <Grid item xs={6}>
        {sectionQuestions.map((question, questionIndex) => (
          <QuestionAccordion
            key={`${question.id} ${questionIndex}`}
            questionIndex={questionIndex + 1}
            title={question.title}
            description={question.description}
            expanded={accordionExpanded === question.id}
            selected={getSelectedButtonIndexFromScore(autonomyDomainScores[questionIndex])}
            onSelect={onResponseSelect(questionIndex)}
            handleChange={handleChange(question.id)}
          />
        ))}
      </Grid>
      <Stack
        alignSelf="flex-end"
        direction="row"
        gap={theme.spacing(4)}
        marginTop={theme.spacing(10)}
      >
        <SkipDomainObservationButton onClick={skipDomainObservationCallback} />
        <SubmitDiagnosisButton
          observationScores={observationScores}
          onClick={submitObservation}
          loading={submitObservationMutation.isLoading}
        />
      </Stack>
      <SubmissionSuccessDialog open={isSuccessDialogVisible} />
      <SubmissionErrorSnackbar
        open={isErrorSnackbarVisible}
        onClose={() => showErrorSnackbar(false)}
      />
    </Grid>
  );
};
