import { useMemo } from 'react';
import { getAutonomyDomainProgressionDataset } from '~/modules/autonomyProgression/domain/progressionChartData';
import { getOptionsFromDataPoints } from '~/modules/autonomyProgression/domain/progressionChartOptions';
import { Observation } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';

export const useAutonomyProgressionChart = (
  observations: Observation[],
  domain: ObservationDomain
) => {
  const chartOptions = useMemo(() => {
    const { datapoints, missingDataRanges } = getAutonomyDomainProgressionDataset(
      observations,
      domain
    );
    const options = getOptionsFromDataPoints(datapoints, missingDataRanges);
    return options;
  }, [observations, domain]);

  return { chartOptions };
};
