import { DialogProps, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from '~/modules/observation/components/ObservationSection/views/Submission/SubmissionSuccessDialog.style';
import { StyledButton } from '~/shared/components/StyledButton';

export const SUCCESS_DIALOG_LABEL = 'success-dialog';
export const SUCCESS_DIALOG_TITLE = 'Merci !';
export const SUCCESS_DIALOG_TEXT = 'Votre diagnostic a bien été pris en compte.';

export const SUCCESS_DIALOG_BUTTON_TEXT = 'Ok';
export const SUCCESS_DIALOG_BUTTON_LABEL = 'success-dialog-button';

type SubmissionSuccessDialogProps = Omit<DialogProps, 'title' | 'children'>;
export const SubmissionSuccessDialog = (props: SubmissionSuccessDialogProps) => {
  const navigate = useNavigate();

  return (
    <StyledDialog {...props} aria-label={SUCCESS_DIALOG_LABEL} disableEscapeKeyDown>
      <StyledDialogTitle>
        <Typography variant="h3">{SUCCESS_DIALOG_TITLE}</Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="body1" gutterBottom>
          {SUCCESS_DIALOG_TEXT}
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton
          variant="contained"
          onClick={() => navigate('/')}
          aria-label={SUCCESS_DIALOG_BUTTON_LABEL}
        >
          {SUCCESS_DIALOG_BUTTON_TEXT}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};
