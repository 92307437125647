import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from '~/core/auth/AuthContext';
import { FeatureFlagsProvider } from '~/core/featureFlags';
import { router } from '~/core/router';
import { addEnvNameToAppTitle } from '~/shared/environment/appTitle';
import { theme } from '~/shared/theme/theme';
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const APP_TITLE = `MILOrizons`;

function App() {
  const appTitleWithEnv = addEnvNameToAppTitle(APP_TITLE);

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>{appTitleWithEnv}</title>
        <meta name="description" content="MILOrizons by UNML" />
      </Helmet>
      <ReactQueryDevtools />
      <AuthProvider>
        <StrictMode>
          <FeatureFlagsProvider>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </FeatureFlagsProvider>
        </StrictMode>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
