import { Box, Card, Grid } from '@mui/material';
import { theme } from '~/shared/theme/theme';

type LabelItem = { text: string; style?: React.CSSProperties };
export interface SurveyButtonProps {
  image: string;
  labels: LabelItem[];
  onClick: () => void;
  selected: boolean;
}
const IMG_DEFAULT_LABEL = 'Image';

export default function SurveyButton({ image, labels, onClick, selected }: SurveyButtonProps) {
  const getLabel = (label: LabelItem) => {
    return (
      <span
        style={{
          fontSize: 14,
          color: theme.palette.pink.main,
          ...label.style,
        }}
        color="white"
        key={label.text}
      >
        {label.text}
      </span>
    );
  };
  return (
    <Card
      onClick={onClick}
      style={{
        cursor: 'pointer',
        borderRadius: 12,
        border: `${selected ? 3 : 1}px solid ${theme.palette.pink.main}`,
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-ends',
        padding: 0,
        gap: theme.spacing(2),
      }}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="flex-end"
        paddingTop={theme.spacing(4)}
      >
        <Box style={{ minHeight: 95 }}>
          <img src={image} alt={IMG_DEFAULT_LABEL} />
        </Box>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
          paddingBottom: theme.spacing(0),
        }}
      >
        <Box
          px={theme.spacing(1)}
          style={{
            textAlign: 'center',
          }}
        >
          {labels.map(getLabel)}
        </Box>
      </div>
    </Card>
  );
}
