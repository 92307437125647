import { CircularProgress, Stack, Typography } from '@mui/material';
import { YoungAdultInfo } from '~/modules/observation/components/ObservationNavBar/domain/extractYoungAdultInfo';
import { StyledYoungAdultDiagnosisInfoCard } from '~/modules/observation/components/ObservationNavBar/views/YoungAdultInfo.style';
import { theme } from '~/shared/theme/theme';
import { ValuesType } from '~/shared/types';

const CURRENT_DIAGNOSIS = 'Diagnostic en cours';

const DiagnosisInfo = {
  Id: 'Identifiant',
  NationalSupport: 'Dispositif national',
  Age: 'Âge',
  LastDiagnosis: 'Dernier diagnostic',
} as const;
type DiagnosisInfo = ValuesType<typeof DiagnosisInfo>;

export interface YoungAdultObservationInfoCardProps {
  youngAdultInfo?: YoungAdultInfo;
  isLoading?: boolean;
}

export const YoungAdultObservationInfoCard = ({
  isLoading,
  youngAdultInfo,
}: YoungAdultObservationInfoCardProps) => {
  if (youngAdultInfo === undefined || isLoading === true)
    return (
      <StyledYoungAdultDiagnosisInfoCard alignItems="center">
        <CircularProgress />
      </StyledYoungAdultDiagnosisInfoCard>
    );

  const currentDiagnosis = `${CURRENT_DIAGNOSIS} : ${youngAdultInfo.currentObservationNumber}`;

  return (
    <StyledYoungAdultDiagnosisInfoCard>
      <Typography variant="h5" gutterBottom>
        {youngAdultInfo.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {currentDiagnosis}
      </Typography>
      <InfoRow name={DiagnosisInfo.Id} value={youngAdultInfo.fileId} />
      <InfoRow name={DiagnosisInfo.NationalSupport} value={youngAdultInfo.nationalSupportPlan} />
      <InfoRow name={DiagnosisInfo.Age} value={youngAdultInfo.age} />
      <InfoRow name={DiagnosisInfo.LastDiagnosis} value={youngAdultInfo.lastObservationDate} />
    </StyledYoungAdultDiagnosisInfoCard>
  );
};

const InfoRow = ({ name, value }: { name: DiagnosisInfo; value: string }) => {
  return (
    <Stack direction="row" gap={theme.spacing(2)}>
      <Typography variant="body1" flex="none" color={theme.palette.grey['300']}>
        {`${name} :`}
      </Typography>
      <Typography variant="body1" noWrap>
        {value}
      </Typography>
    </Stack>
  );
};
