import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import PrivateRoute from '~/core/components/auth/PrivateRoute';
import { PageWithHeader } from '~/core/components/header/PageWithHeader';
import { AdminConnexionPage } from '~/modules/admin/views/AdminConnexionPage';
import { AdminDashboardPage } from '~/modules/admin/views/AdminDashboardPage';
import { AutonomyProgressionPage } from '~/modules/autonomyProgression/views/AutonomyProgressionPage';
import { SurveyPageContainer } from '~/modules/observation/views/SurveyPageContainer';
import { ProgressDashboardPage } from '~/modules/progressDashboard/views/ProgressDashboardPage';

export const PROGRESS_DASHBOARD_ROUTE_PATH = '/';
export const SURVEY_ROUTE_PATH = '/observation';
export const AUTONOMY_PROGRESSION_ROUTE_PATH = '/progression';
export const ADMIN_CONNEXION_PAGE_ROUTE_PATH = '/admin/connexion';
export const ADMIN_DASHBOARD_PAGE_ROUTE_PATH = '/admin';

export const routes: RouteObject[] = [
  {
    path: PROGRESS_DASHBOARD_ROUTE_PATH,
    element: (
      <PrivateRoute>
        <PageWithHeader>
          <ProgressDashboardPage />
        </PageWithHeader>
      </PrivateRoute>
    ),
  },
  {
    path: `${AUTONOMY_PROGRESSION_ROUTE_PATH}/:imiloId`,
    element: (
      <PrivateRoute>
        <PageWithHeader>
          <AutonomyProgressionPage />
        </PageWithHeader>
      </PrivateRoute>
    ),
  },
  {
    path: `${SURVEY_ROUTE_PATH}/:imiloId`,
    element: (
      <PrivateRoute>
        <PageWithHeader>
          <SurveyPageContainer />
        </PageWithHeader>
      </PrivateRoute>
    ),
  },
  {
    path: `${ADMIN_CONNEXION_PAGE_ROUTE_PATH}`,
    element: <AdminConnexionPage />,
  },
  {
    path: `${ADMIN_DASHBOARD_PAGE_ROUTE_PATH}`,
    element: <AdminDashboardPage />,
  },
  {
    path: '*',
    element: <Navigate to={PROGRESS_DASHBOARD_ROUTE_PATH} />,
  },
];

export const router = createBrowserRouter(routes);
