import { alpha } from '@mui/material';
import * as echarts from 'echarts';
import { MissingDataRange } from '~/modules/autonomyProgression/domain/progressionChartData';
import { ProgressionDataPoint } from '~/modules/autonomyProgression/domain/progressionChartData.schema';
import {
  observationLabelFormatter,
  tooltipFormatter,
} from '~/modules/autonomyProgression/domain/progressionChartOptions.formatters';
import { domainQuestions } from '~/modules/observation/domain/observationQuestions';
import { theme } from '~/shared/theme/theme';

const BASE_LINE_SERIES_OPTIONS: echarts.LineSeriesOption = {
  type: 'line',
  smooth: 0.5,
  lineStyle: {
    width: 3,
  },
  connectNulls: true,
  showSymbol: true,
  symbol: 'circle',
  symbolSize: 8,
};

const BASE_SERIES_1_OPTION: echarts.LineSeriesOption = {
  ...BASE_LINE_SERIES_OPTIONS,
  name: domainQuestions[0].title,
  dimensions: ['observationNumber', 'question1'], // these names should concile with CustomCharDataPoints property names
  color: theme.palette.chartColor1.main,
};

const BASE_SERIES_2_OPTION: echarts.LineSeriesOption = {
  ...BASE_LINE_SERIES_OPTIONS,
  name: domainQuestions[1].title,
  dimensions: ['observationNumber', 'question2'], // these names should concile with CustomCharDataPoints property names
  color: theme.palette.chartColor2.main,
};

const BASE_SERIES_3_OPTION: echarts.LineSeriesOption = {
  ...BASE_LINE_SERIES_OPTIONS,
  name: domainQuestions[2].title,
  dimensions: ['observationNumber', 'question3'], // these names should concile with CustomCharDataPoints property names
  color: theme.palette.chartColor3.main,
};

const BASE_MARK_AREA: echarts.MarkAreaComponentOption = {
  silent: true,
  itemStyle: {
    color: alpha(theme.palette.grey[100], 0.5),
  },
};

const BASE_VISUAL_MAP: echarts.VisualMapComponentOption = {
  show: false,
  dimension: 0,
  inRange: { color: alpha(theme.palette.grey[200], 0.5) },
};

const BASE_XAXIS_OPTION: echarts.XAXisComponentOption = {
  type: 'category',
  boundaryGap: false,
  name: 'Diagnostics',
  nameLocation: 'middle',
  nameGap: 30,
  axisTick: { show: false },
  axisLine: { show: false },
  axisLabel: {
    margin: 10,
    formatter: observationLabelFormatter,
  },
};

const BASE_YAXIS_OPTION: echarts.YAXisComponentOption = {
  type: 'value',
  min: 1,
  max: 5,
  interval: 1,
  show: true,
  axisLabel: { show: false },
  splitLine: {
    lineStyle: {
      width: 1,
      shadowColor: 'rgba(0, 0, 0, 0.1)',
      shadowBlur: 1,
      shadowOffsetY: 1,
    },
  },
};

export const BASE_TOOLTIP_OPTION: echarts.TooltipComponentOption = {
  trigger: 'axis',
  axisPointer: {
    type: 'line',
  },
  // TECHNICAL DEBT - Cannot find a way to type this correctly because the typing is flawed : it does not allow
  // null values in datapoints whereas we can use null values in reality
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  formatter: tooltipFormatter,
};

export const BASE_LEGEND_OPTION: echarts.LegendComponentOption = {
  show: true,
  selectedMode: false,
  bottom: 0,
  type: 'scroll',
};

export const BASE_GRID_OPTION: echarts.GridComponentOption = {
  containLabel: true,
  top: theme.spacing(10),
  bottom: theme.spacing(10),
  right: theme.spacing(6),
  left: theme.spacing(6),
};

export const BASE_CHART_ANIMATION_DURATION = 500;

const BASE_CHART_OPTIONS: echarts.EChartsOption = {
  animationDuration: BASE_CHART_ANIMATION_DURATION,
  xAxis: BASE_XAXIS_OPTION,
  yAxis: [BASE_YAXIS_OPTION],
  series: [BASE_SERIES_1_OPTION, BASE_SERIES_2_OPTION, BASE_SERIES_3_OPTION],
  tooltip: BASE_TOOLTIP_OPTION,
  legend: BASE_LEGEND_OPTION,
  grid: BASE_GRID_OPTION,
};

export const getMarkAreaFromMissingDataRanges = (
  missingDataRanges: MissingDataRange[]
): echarts.MarkAreaComponentOption => {
  const markAreaData: echarts.MarkAreaComponentOption['data'] = missingDataRanges.map((range) => [
    { xAxis: range.leftIndex },
    { xAxis: range.rightIndex },
  ]);

  return { ...BASE_MARK_AREA, data: markAreaData };
};

export const getVisualMapFromMissingDataRanges = (
  missingDataRanges: MissingDataRange[]
): echarts.VisualMapComponentOption[] => {
  if (missingDataRanges.length === 0) return [];

  const visualMapsPieces: { min: number; max: number }[] = missingDataRanges.map((range) => ({
    min: range.leftIndex + 0.01, // Needed to avoid greyed symbols on frontiers
    max: range.rightIndex - 0.01, // Needed to avoid greyed symbols on frontiers
  }));

  const visualMaps: echarts.VisualMapComponentOption[] = [
    BASE_SERIES_1_OPTION,
    BASE_SERIES_2_OPTION,
    BASE_SERIES_3_OPTION,
  ].map((seriesOption, index) => ({
    ...BASE_VISUAL_MAP,
    seriesIndex: index,
    outOfRange: { color: seriesOption.color },
    pieces: visualMapsPieces,
  }));

  return visualMaps;
};

export const getOptionsFromDataPoints = (
  dataPoints: ProgressionDataPoint[],
  missingDataRanges?: MissingDataRange[]
): echarts.EChartsOption => {
  const dataset: echarts.DatasetComponentOption = {
    dimensions: ['observationNumber', 'observationDate', 'question1', 'question2', 'question3'], // these names should concile with CustomCharDataPoints property names
    source: dataPoints as echarts.DatasetComponentOption['source'],
  };

  const markArea = getMarkAreaFromMissingDataRanges(missingDataRanges ?? []);
  const visualMap = getVisualMapFromMissingDataRanges(missingDataRanges ?? []);
  const series1 = { ...BASE_SERIES_1_OPTION, markArea };
  const series: echarts.SeriesOption[] = [series1, BASE_SERIES_2_OPTION, BASE_SERIES_3_OPTION];

  return { ...BASE_CHART_OPTIONS, dataset, series, visualMap };
};
