import { Typography } from '@mui/material';
import { StyledAutonomyProgressionEmptyContainer } from '~/modules/autonomyProgression/views/AutonomyProgressionEmpty.style';

export const NO_PROGRESSION_VISUALIZATION =
  "La visualisation de la progression n'est pas disponible pour les usagers de la population 1";

export const AutonomyNoProgressionAvailable = () => {
  return (
    <StyledAutonomyProgressionEmptyContainer>
      <Typography variant="body1">{NO_PROGRESSION_VISUALIZATION}</Typography>
    </StyledAutonomyProgressionEmptyContainer>
  );
};
