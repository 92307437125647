import { capitalize } from '@mui/material';
import { differenceInYears, format, formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';
import { YoungAdultFileWithObservationSummary } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';

export type YoungAdultInfo = {
  fileId: string;
  name: string;
  age: string;
  nationalSupportPlan: string;
  currentObservationNumber: string;
  submittedObservationsCount: string;
  firstObservationDate: string;
  lastObservationDate: string;
  timeSinceLastObservationDate: string;
};

export const extractYoungAdultFileInfo = (
  youngAdultFile: YoungAdultFileWithObservationSummary
): YoungAdultInfo => {
  const fileId = _extractFileId(youngAdultFile);
  const name = _extractName(youngAdultFile);
  const age = _extractAge(youngAdultFile);
  const nationalSupportPlan = _extractNationSupportPlan(youngAdultFile);
  const currentObservationNumber = _extractCurrentObservationNumber(youngAdultFile);
  const submittedObservationsCount = _extractSubmittedObservationsCount(youngAdultFile);
  const firstObservationDate = _extractFirstObservationDate(youngAdultFile);
  const lastObservationDate = _extractLastObservationDate(youngAdultFile);
  const timeSinceLastObservationDate = _extractTimeSinceLastObservationDate(youngAdultFile);

  return {
    fileId,
    name,
    age,
    nationalSupportPlan,
    currentObservationNumber,
    submittedObservationsCount,
    firstObservationDate,
    lastObservationDate,
    timeSinceLastObservationDate,
  };
};

export const _extractFileId = (youngAdultFile: YoungAdultFileWithObservationSummary) =>
  youngAdultFile.imiloId;

export const _extractName = (youngAdultFile: YoungAdultFileWithObservationSummary) =>
  `${capitalize(youngAdultFile.firstname)} ${capitalize(youngAdultFile.lastname)}`;

export const _extractAge = (youngAdultFile: YoungAdultFileWithObservationSummary) =>
  `${differenceInYears(new Date(), new Date(youngAdultFile.birthDate))} ans`;

export const _extractNationSupportPlan = (youngAdultFile: YoungAdultFileWithObservationSummary) =>
  youngAdultFile.nationalSupportPlan ?? '-';

export const _extractCurrentObservationNumber = (
  youngAdultFile: YoungAdultFileWithObservationSummary
) => `n° ${youngAdultFile.observationCount + 1}`;

export const _extractSubmittedObservationsCount = (
  youngAdultFile: YoungAdultFileWithObservationSummary
) =>
  youngAdultFile.observations
    .filter((observation) => observation.status === 'submitted')
    .length.toString();

export const _extractFirstObservationDate = (
  youngAdultFile: YoungAdultFileWithObservationSummary
) =>
  youngAdultFile.firstObservationDate === null
    ? '-'
    : format(new Date(youngAdultFile.firstObservationDate), 'dd/MM/yyyy');

export const _extractLastObservationDate = (youngAdultFile: YoungAdultFileWithObservationSummary) =>
  youngAdultFile.lastObservationDate === null
    ? '-'
    : format(new Date(youngAdultFile.lastObservationDate), 'dd/MM/yyyy');

export const _extractTimeSinceLastObservationDate = (
  youngAdultFile: YoungAdultFileWithObservationSummary
) =>
  youngAdultFile.lastObservationDate === null
    ? '-'
    : formatDistance(new Date(), new Date(youngAdultFile.lastObservationDate), {
        locale: fr,
      });
