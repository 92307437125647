import {
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableContainerProps,
  TableProps,
  TableRow,
} from '@mui/material';
import { theme } from '~/shared/theme/theme';

export const StyledProgressTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.grey[300],
  },
  [`&.${tableCellClasses.body}`]: {
    font: theme.typography.body2,
  },
  [`&.${tableCellClasses.root}`]: {
    border: 0,
  },
}));

export const StyledProgressTableBodyCell = styled(StyledProgressTableHeadCell)({
  height: 70,
});

export const StyledProgressTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ clickable }: { clickable?: boolean }) => ({
  ':hover':
    clickable === false
      ? 'inherent'
      : {
          backgroundColor: theme.palette.grey[50],
          cursor: 'pointer',
        },
}));

export const StyledProgressTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== 'offsetTop',
})(({ offsetTop }: TableContainerProps & { offsetTop: number }) => ({
  height: window.innerHeight - offsetTop - parseInt(theme.spacing(11)),
}));

export const StyledProgressTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})(({ fullHeight }: TableProps & { fullHeight: boolean }) => ({
  position: 'relative',
  height: fullHeight ? '100%' : undefined,
  overflow: 'hidden',
}));
