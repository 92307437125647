import { useEffect, useState } from 'react';
import {
  AccordionExpanded,
  firstAccordion,
  secondAccordion,
  SurveySection,
  surveySections,
  thirdAccordion,
} from '~/modules/observation/components/ObservationSection/domain/surveySections';
import { getNextSectionIndex } from '~/modules/observation/domain/getNextSection';

interface UseSectionManagerProps {
  confirmSection: (surveySection: SurveySection) => void;
}

export const useSectionManager = ({ confirmSection }: UseSectionManagerProps) => {
  const [currentSection, setCurrentSection] = useState<SurveySection>(surveySections[0]);
  const [accordionExpanded, setAccordionExpanded] = useState<AccordionExpanded | false>(
    firstAccordion
  );

  const goToNextSection = (fromSection: SurveySection) => {
    confirmSection(fromSection);
    const sectionIndex = surveySections.indexOf(fromSection);
    const nextSectionIndex = getNextSectionIndex(sectionIndex);
    const nextSection = surveySections[nextSectionIndex];
    goToSection(nextSection);
  };

  const handleChange =
    (panel: AccordionExpanded) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setAccordionExpanded(isExpanded ? panel : false);
    };

  const expandNextAccordion = () => {
    switch (accordionExpanded) {
      case firstAccordion:
        setAccordionExpanded(secondAccordion);
        break;
      case secondAccordion:
        setAccordionExpanded(thirdAccordion);
        break;
      case thirdAccordion:
        goToNextSection(currentSection);
        break;
    }
  };

  useEffect(() => {
    setAccordionExpanded(firstAccordion);
  }, [currentSection]);

  const goToSection: GoToSection = (section: SurveySection) => {
    setCurrentSection(section);
  };

  return {
    currentSection,
    goToSection,
    accordionExpanded,
    setAccordionExpanded,
    handleChange,
    expandNextAccordion,
    skipSection: goToNextSection,
  };
};

export type GoToSection = (section: SurveySection) => void;

export type HandleChange = (
  panel: AccordionExpanded
) => (event: React.SyntheticEvent, isExpanded: boolean) => void;

export type SkipSection = (section: SurveySection) => void;
