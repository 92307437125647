import {
  Avatar,
  AvatarProps,
  Divider,
  DividerProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material';

export const StyledHeader = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.drawer + 1,
  width: '100%',
  backgroundColor: theme.palette.common.white,
}));

export const StyledHeaderContent = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(13),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const StyledHeaderUserInfo = styled(Stack)<StackProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(5),
}));

export const StyledAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  width: '34px',
  height: '34px',
}));

export const StyledDivider = styled(Divider)<DividerProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
}));
