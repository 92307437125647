import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { StyledConnectionForm, StyledForm } from '~/modules/connection/views/ConnectionForm.style';
import { StyledButton } from '~/shared/components/StyledButton';
import { theme } from '~/shared/theme/theme';

export const CONNECTION_PAGE_LOGO_ROUTE_PATH = '/MILOrizons-Vertical-Logo.svg';
export const CONNECTION_PAGE_LOGO_LABEL = 'Connection_Page_logo';
export const CONNECTION_PAGE_BUTTON_LABEL = 'Connection_Page_button';
export const CONNECTION_PAGE_LOGO_TEXT = 'Se connecter';
export const CONNECTION_PAGE_TITLE_TEXT = 'Se connecter';

export const ConnectionForm = () => {
  const { keycloak } = useKeycloak();

  const loginAndNavigateToHomePage = () => {
    keycloak.login({
      prompt: 'login',
      redirectUri: window.location.href,
    });
  };

  return (
    <StyledConnectionForm>
      <img
        src={CONNECTION_PAGE_LOGO_ROUTE_PATH}
        aria-label={CONNECTION_PAGE_LOGO_LABEL}
        alt={CONNECTION_PAGE_LOGO_LABEL}
        height="60px"
      />
      <Typography variant="h5" paddingBottom={theme.spacing(3)}>
        {CONNECTION_PAGE_TITLE_TEXT}
      </Typography>
      <form style={StyledForm}>
        <StyledButton
          variant="contained"
          endIcon={<ArrowForwardRoundedIcon />}
          color="primary"
          aria-label={CONNECTION_PAGE_BUTTON_LABEL}
          onClick={loginAndNavigateToHomePage}
        >
          {CONNECTION_PAGE_LOGO_TEXT}
        </StyledButton>
      </form>
    </StyledConnectionForm>
  );
};
