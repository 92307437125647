import axios from 'axios';
import { getAxiosOptions } from '~/core/api/axiosInstance';
import { AutonomyData } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';

export const getBaseRoute = (imiloId: string) => {
  return `/young-adult-files/${imiloId}/observations`;
};

export type GetFileObservationsParams = {
  imiloId: string;
};
export const getAutonomyProgressionData = async ({
  imiloId,
}: GetFileObservationsParams): Promise<AutonomyData> => {
  const baseRoute = getBaseRoute(imiloId);

  const params = { status: 'submitted' };
  const options = { ...getAxiosOptions(), params };

  const response = await axios.get(baseRoute, options);

  return response.data;
};
