import { StyledSidebar } from '~/shared/components/Sidebar.style';

export const SIDEBAR_LABEL = 'app-sidebar';

export type SidebarProps = {
  children?: JSX.Element;
};
export const Sidebar = ({ children }: SidebarProps) => {
  return <StyledSidebar aria-label={SIDEBAR_LABEL}>{children}</StyledSidebar>;
};
