import { Alert, Snackbar, SnackbarProps } from '@mui/material';

export const SUBMISSION_ERROR_SNACKBAR_LABEL = 'submission-error-snackbar';
export const SUBMISSION_ERROR_MESSAGE = 'Un problème est survenu, veuillez réessayer';

export const SubmissionErrorSnackbar = (props: SnackbarProps) => {
  return (
    <Snackbar
      {...props}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      aria-label={SUBMISSION_ERROR_SNACKBAR_LABEL}
    >
      <Alert severity="warning" variant="filled">
        {SUBMISSION_ERROR_MESSAGE}
      </Alert>
    </Snackbar>
  );
};
