export type Question = { title: string; description: string };

export const domainQuestions: Question[] = [
  {
    title: 'Capacité à être accompagné',
    description:
      "Il s'agit de la capacité de l'usager à mobiliser les acteurs (Ex : Pôle Emploi, Cap Emploi, Agence d'emploi, entreprises, etc.) et les dispositifs (Ex : PACEA, GJ, AIJ, IAE, etc.) utiles à son parcours.",
  },
  {
    title: 'Capacité à outiller son parcours',
    description:
      "Il s'agit de la capacité de l'usager à articuler les outils utiles à son parcours (exemple: TRE, Site internet, outils numérique et techniques de communication, etc.).",
  },
  {
    title: 'Capacité à adopter les comportements adéquats',
    description:
      "Il s'agit de la capacité de l'usager à adopter les bons comportements (Ex. postures, savoir être, motivations, etc.) utiles à son parcours.",
  },
];
