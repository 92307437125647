import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  DomainObservationStatus,
  domainObservationStatusIcon,
} from '~/modules/observation/components/ObservationNavBar/domain/domainCompletion';
import {
  ObservationDomain,
  observationDomainIcons,
  observationDomainLabels,
} from '~/modules/observation/domain/observationDomains';
import { GoToSection } from '~/modules/observation/domain/useSectionManager';
import { theme } from '~/shared/theme/theme';

interface ProgressionSurveyStepProps {
  domain: ObservationDomain;
  status?: DomainObservationStatus | undefined;
  goToSection: GoToSection;
  focused: boolean;
}

const ThemeDomainButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'focused',
})<ListItemButtonProps & Pick<ProgressionSurveyStepProps, 'focused'>>(({ focused }) => ({
  backgroundColor: focused ? '#FFF' : undefined,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(0),
}));

const ThemeDomainName = styled(Typography)<
  TypographyProps & Pick<ProgressionSurveyStepProps, 'status' | 'focused'>
>(({ status, focused }) => {
  const isBold = status === 'inProgress' || (status === undefined && focused);
  return {
    fontWeight: isBold ? 700 : undefined,
  };
});

export const ProgressionSurveyStep = ({
  domain,
  status,
  goToSection,
  focused,
}: ProgressionSurveyStepProps) => {
  const label = observationDomainLabels[domain];
  const DomainIcon = observationDomainIcons[domain];
  const StatusIcon = status ? domainObservationStatusIcon[status] : undefined;

  const handleClick = () => {
    goToSection(domain);
  };

  return (
    <ThemeDomainButton focused={focused} onClick={handleClick}>
      <ListItemIcon>
        <DomainIcon />
      </ListItemIcon>
      <ListItemText>
        <ThemeDomainName status={status} focused={focused} variant="body1" flex={1}>
          {label}
        </ThemeDomainName>
      </ListItemText>
      {StatusIcon ? (
        <ListItemIcon sx={{ justifyContent: 'end' }}>
          <StatusIcon />
        </ListItemIcon>
      ) : null}
    </ThemeDomainButton>
  );
};
