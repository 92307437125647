import {
  observationDomainLabels,
  observationDomains,
} from '~/modules/observation/domain/observationDomains';
import { AutonomyScore, ObservationScores } from '~/modules/observation/domain/observationScores';

export const isAutonomyDomainFullyObserved = (autonomyScore: AutonomyScore) => {
  return autonomyScore.reduce(
    (isFullyObserved, score) => isFullyObserved && score !== null && score > 0,
    true
  );
};

export const isAutonomyDomainSkipped = (autonomyScore: AutonomyScore) => {
  return autonomyScore.reduce((isSkipped, score) => isSkipped && score === 0, true);
};

const getDomainsFullyObserved = (observationScores: ObservationScores) => {
  const fullyObservedDomains = observationDomains.filter((domain) =>
    isAutonomyDomainFullyObserved(observationScores[domain])
  );
  return fullyObservedDomains;
};

const getDomainsPartiallyObserved = (observationScores: ObservationScores) => {
  const partiallyObservedDomains = observationDomains.filter(
    (domain) =>
      !isAutonomyDomainFullyObserved(observationScores[domain]) &&
      !isAutonomyDomainSkipped(observationScores[domain])
  );
  return partiallyObservedDomains;
};

export type ObservationSubmissionValidation = {
  isValid: boolean;
  reason?: string;
};
export const ERROR_TEXT_LESS_THAN_3_DOMAINS_OBSERVED = 'Veuillez observer au moins 3 domaines';
export const ERROR_TEXT_DOMAINS_PARTIALLY_OBSERVED = 'Veuillez répondre complètement au domaine';

export const validateObservationForSubmission = (
  observationScores: ObservationScores
): ObservationSubmissionValidation => {
  const domainsFullyObserved = getDomainsFullyObserved(observationScores);
  const isBelowFullObservationsThreshold = domainsFullyObserved.length < 3;

  if (isBelowFullObservationsThreshold) {
    return {
      isValid: false,
      reason: ERROR_TEXT_LESS_THAN_3_DOMAINS_OBSERVED,
    };
  }

  const domainsPartiallyObserved = getDomainsPartiallyObserved(observationScores);
  const hasIncompleteDomains = domainsPartiallyObserved.length > 0;

  if (hasIncompleteDomains) {
    return {
      isValid: false,
      reason: `${ERROR_TEXT_DOMAINS_PARTIALLY_OBSERVED} ${
        observationDomainLabels[domainsPartiallyObserved[0]]
      }`,
    };
  }

  return { isValid: true };
};
