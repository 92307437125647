import { useRef, useState } from 'react';
import validator from 'validator';

export const useEmailTextField = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const helperTextDelayMs = 1000;
  const timer = useRef<number | undefined>(undefined);
  const [helperText, setHelperText] = useState('');

  const changeHelperTextWithDelay = (newHelperText: string, delay: number) => {
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(() => setHelperText(newHelperText), delay);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmailValue = event.target.value;

    setEmail(newEmailValue);

    const isValid = validator.isEmail(newEmailValue);
    setIsValidEmail(isValid);

    const isValidOrEmpty = isValid || newEmailValue === '';
    const newHelperText = isValidOrEmpty ? '' : 'Email invalide';
    const delay = isValidOrEmpty ? 0 : helperTextDelayMs;
    changeHelperTextWithDelay(newHelperText, delay);
  };

  return { email, isValidEmail, helperText, handleEmailChange };
};
