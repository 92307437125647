import { SvgIconComponent } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import {
  isAutonomyDomainFullyObserved,
  isAutonomyDomainSkipped,
} from '~/modules/observation/domain/observationCompletion';
import {
  AutonomyScore,
  isInitialAutonomyScore,
} from '~/modules/observation/domain/observationScores';

export type DomainObservationStatus = 'toDo' | 'inProgress' | 'skipped' | 'done';

export const isDomainCompleted = (autonomyScore: AutonomyScore) => {
  return isAutonomyDomainFullyObserved(autonomyScore) || isAutonomyDomainSkipped(autonomyScore);
};

export const getDomainObservationStatus = (
  autonomyScore: AutonomyScore
): DomainObservationStatus => {
  if (isAutonomyDomainFullyObserved(autonomyScore)) return 'done';

  if (isAutonomyDomainSkipped(autonomyScore)) return 'skipped';

  if (!isInitialAutonomyScore(autonomyScore)) return 'inProgress';

  return 'toDo';
};

export const domainObservationStatusIcon: Record<DomainObservationStatus, SvgIconComponent> = {
  toDo: CircleOutlinedIcon,
  inProgress: PendingOutlinedIcon,
  skipped: RemoveCircleOutlineOutlinedIcon,
  done: TaskAltOutlinedIcon,
};
