import AddIcon from '@mui/icons-material/Add';
import { Alert, Snackbar, Stack, StackProps } from '@mui/material';
import { FormEvent, useState } from 'react';
import { getSnackbarColor, getSnackbarMessage } from '~/modules/progressDashboard/domain/snackbar';
import { useYoungAdultMutations } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileMutations';
import { StyledButton } from '~/shared/components/StyledButton';
import { StyledTextField } from '~/shared/components/StyledTextfield';
import { theme } from '~/shared/theme/theme';

export const FOLLOW_YOUNG_ADULT_INPUT_LABEL = 'follow-user-input-label';
const FOLLOW_YOUNG_ADULT_INPUT_PLACEHOLDER = "Identifiant de l'usager";
export const FOLLOW_YOUNG_ADULT_BUTTON_LABEL = 'follow-user';
export const FOLLOW_YOUNG_ADULT_BUTTON_TEXT = 'Suivre un usager existant';

export const FOLLOW_YOUNG_ADULT_SNACKBAR_LABEL = 'follow-user-snackbar';
export const FOLLOW_YOUNG_ADULT_ERROR_MSG = 'Impossible de suivre cet usager';
export const FOLLOW_YOUNG_ADULT_ALREADY_FOLLOWED_MSG = 'Cet usager figure déjà dans votre liste';
export const FOLLOW_YOUNG_ADULT_SUCCESS_MSG = "L'usager a bien été suivi";

export const FollowYoungAdultForm = (props: StackProps<'form'>) => {
  const [imiloId, setImiloId] = useState('');

  const [isSnackbarVisible, showSnackbar] = useState(false);

  const { followImiloYoungAdultMutation } = useYoungAdultMutations();
  const followYoungAdult = (e: FormEvent) => {
    followImiloYoungAdultMutation.mutate({ imiloId }, { onSettled: () => showSnackbar(true) });
    e?.preventDefault();
  };

  const snackbarMessage = {
    alreadyFollowed: FOLLOW_YOUNG_ADULT_ALREADY_FOLLOWED_MSG,
    error: FOLLOW_YOUNG_ADULT_ERROR_MSG,
    success: FOLLOW_YOUNG_ADULT_SUCCESS_MSG,
  };

  return (
    <Stack
      gap={theme.spacing(5)}
      direction="row"
      component={'form'}
      onSubmit={followYoungAdult}
      {...props}
    >
      <StyledTextField
        variant="outlined"
        fullWidth
        size="small"
        sx={{ width: 'fit-content' }}
        placeholder={FOLLOW_YOUNG_ADULT_INPUT_PLACEHOLDER}
        inputProps={{
          'aria-label': FOLLOW_YOUNG_ADULT_INPUT_LABEL,
        }}
        onChange={(e) => setImiloId(e.target.value)}
      />
      <StyledButton
        type="submit"
        color="primary"
        variant="contained"
        aria-label={FOLLOW_YOUNG_ADULT_BUTTON_LABEL}
        loading={followImiloYoungAdultMutation.isLoading}
        endIcon={<AddIcon />}
        sx={{ height: 'fit-content', width: 'fit-content' }}
      >
        {FOLLOW_YOUNG_ADULT_BUTTON_TEXT}
      </StyledButton>
      <Snackbar
        open={isSnackbarVisible}
        onClose={() => showSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-label={FOLLOW_YOUNG_ADULT_SNACKBAR_LABEL}
      >
        <Alert
          severity={getSnackbarColor(followImiloYoungAdultMutation.isSuccess)}
          variant="filled"
        >
          {getSnackbarMessage(snackbarMessage, followImiloYoungAdultMutation.error ?? undefined)}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
