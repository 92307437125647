import { Box, styled } from '@mui/material';

export const StyledCenteredTableContent = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: '100%',
  width: '100%',
  position: 'relative',
  top: '0',
});
