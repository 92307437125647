import { Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: 40,
  '&::placeholder': {
    color: theme.palette.grey[300],
  },
  [`& fieldset`]: {
    borderRadius: 8,
    borderColor: `${theme.palette.grey[300]}`,
  },
}));
