import { Box } from '@mui/material';
import { autonomyLevels } from '~/modules/observation/components/ObservationSection/domain/autonomyLevel';
import { Description } from '~/modules/observation/components/ObservationSection/views/QuestionCard.style';
import SurveyButton from '~/modules/observation/components/ObservationSection/views/SurveyButton';
import { theme } from '~/shared/theme/theme';

interface QuestionCardProps {
  description: string;
  onSelect: (index: number) => () => void;
  selected?: number;
}
export const QuestionCard = ({ description, onSelect, selected }: QuestionCardProps) => {
  return (
    <Box>
      <Description variant="surveyCardDescription">{description}</Description>

      <Box justifyContent="stretch" display="flex" gap={theme.spacing(4)}>
        {autonomyLevels.map((prop, index) => (
          <SurveyButton
            key={`button_${index}`}
            image={prop.image}
            labels={prop.labels}
            selected={selected === index}
            onClick={onSelect(index)}
          />
        ))}
      </Box>
    </Box>
  );
};
