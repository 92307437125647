import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { domainQuestions } from '~/modules/observation/domain/observationQuestions';

export type AutonomyScore = (number | null)[];

export type ObservationScores = { [property in ObservationDomain]: AutonomyScore };

export const initialAutonomyScore: AutonomyScore = new Array(domainQuestions.length).fill(null);

export const isInitialAutonomyScore = (autonomyScore: AutonomyScore) => {
  return (
    autonomyScore.length === initialAutonomyScore.length &&
    autonomyScore.every((score, index) => score === initialAutonomyScore[index])
  );
};

export const initialObservationScores = {
  employmentAccess: initialAutonomyScore,
  training: initialAutonomyScore,
  mobility: initialAutonomyScore,
  housing: initialAutonomyScore,
  health: initialAutonomyScore,
  financial: initialAutonomyScore,
  citizenship: initialAutonomyScore,
};
export const skippedObservationScore: AutonomyScore = new Array(domainQuestions.length).fill(0);

export const getInitialObservationScores = (
  initialScores?: Partial<ObservationScores>
): ObservationScores => {
  const scores = { ...initialObservationScores, ...(initialScores ?? {}) };
  return scores;
};
