import ReactECharts from 'echarts-for-react';
import { Observation } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';
import { useAutonomyProgressionChart } from '~/modules/autonomyProgression/views/useAutonomyProgressionChart';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';

export type AutonomyProgressionChartProps = {
  observations: Observation[];
  domain: ObservationDomain;
};
export const AutonomyProgressionChart = ({
  observations,
  domain,
}: AutonomyProgressionChartProps) => {
  const { chartOptions } = useAutonomyProgressionChart(observations, domain);

  return <ReactECharts option={chartOptions} notMerge={true} style={{ flex: 1, height: '100%' }} />;
};
