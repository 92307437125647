import {
  NationalSupportPlan,
  nationalSupportPlans,
} from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';

export const serializeNationalSupportPlan = (nationalSupportPlan: NationalSupportPlan | null) => {
  if (nationalSupportPlan !== null && nationalSupportPlans.includes(nationalSupportPlan))
    return nationalSupportPlan;
  return null;
};
