import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PROGRESS_DASHBOARD_ROUTE_PATH } from '~/core/router';
import { useAutonomyProgressionQuery } from '~/modules/autonomyProgression/infrastructure/useAutonomyProgressionQuery';
import { AutonomyProgressionSection } from '~/modules/autonomyProgression/views/AutonomyProgressionSection';
import { YoungAdultProgressionInfoCard } from '~/modules/autonomyProgression/views/YoungAdultInfoCard';
import { extractYoungAdultFileInfo } from '~/modules/observation/components/ObservationNavBar/domain/extractYoungAdultInfo';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { useYoungAdultFileQuery } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileQuery';
import { useNavigateOnCondition } from '~/shared/components/hooks/useNavigateOnCondition';
import { Sidebar } from '~/shared/components/Sidebar';
import { StyledPage } from '~/shared/components/StyledPage.style';

export const AUTONOMY_PAGE_LABEL = 'autonomy-page';

export const AutonomyProgressionPage = () => {
  const { imiloId } = useParams();

  const { data: youngAdultFile, isLoading: fileIsLoading } = useYoungAdultFileQuery(imiloId ?? '');
  const youngAdultInfo =
    youngAdultFile === undefined ? undefined : extractYoungAdultFileInfo(youngAdultFile);

  const {
    data: autonomyProgressionData,
    isLoading,
    isError,
  } = useAutonomyProgressionQuery(imiloId ?? '');

  const [selectedDomain, setSelectedDomain] = useState<ObservationDomain>('employmentAccess');

  useNavigateOnCondition(isError === true, PROGRESS_DASHBOARD_ROUTE_PATH);

  return (
    <StyledPage aria-label={AUTONOMY_PAGE_LABEL}>
      <Sidebar>
        <YoungAdultProgressionInfoCard
          isLoading={fileIsLoading}
          youngAdultInfo={youngAdultInfo}
          selectedDomain={selectedDomain}
          selectDomain={setSelectedDomain}
        />
      </Sidebar>
      <AutonomyProgressionSection
        observations={autonomyProgressionData?.observations ?? []}
        isLoading={isLoading}
        population={youngAdultFile?.population}
        selectedDomain={selectedDomain}
      />
    </StyledPage>
  );
};
