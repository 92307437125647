import Keycloak from 'keycloak-js';

const localKeycloakConfig = {
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
};

// const iMiloKeycloakConfig = {
//   url: import.meta.env.VITE_KEYCLOAK_IMILO_URL;,
//   realm: 'imilo-qualif',
//   clientId: 'portail-milorizon',
// };

const keycloak = new Keycloak(localKeycloakConfig);

export default keycloak;
