import { Slide, Stack, Typography } from '@mui/material';
import {
  ObservationDomain,
  observationDomainIcons,
  observationDomainLabels,
  observationDomains,
} from '~/modules/observation/domain/observationDomains';
import { theme } from '~/shared/theme/theme';

export const ObservationSectionTitle = ({
  currentSection,
}: {
  currentSection: ObservationDomain;
}) => {
  return (
    <Stack>
      {observationDomains.map((domain) => {
        const Icon = observationDomainIcons[domain];
        const title = observationDomainLabels[domain];
        const isVisible = domain === currentSection;
        return (
          <Slide key={`title_slide_wrapper_${domain}`} direction="down" in={isVisible}>
            <Stack
              aria-label={`title_${domain}`}
              aria-hidden={!isVisible}
              display={isVisible ? 'inherit' : 'none'}
              direction="row"
              alignItems="end"
              gap={theme.spacing(6)}
            >
              <Icon sx={{ fontSize: '120px', color: theme.palette.secondary.dark }} />
              <Typography variant="h2" marginBottom={theme.spacing(8)}>
                {title}
              </Typography>
            </Stack>
          </Slide>
        );
      })}
    </Stack>
  );
};
