import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { StyledButton } from '~/shared/components/StyledButton';

const CONFIRM_YOUNG_ADULT_DELETION_DIALOG_TITLE = 'Voulez-vous vraiment supprimer cet usager ?';
export const CONFIRM_YOUNG_ADULT_DELETION_DIALOG_YES_BUTTON = 'Oui';
const CONFIRM_YOUNG_ADULT_DELETION_DIALOG_NO_BUTTON = 'Non';

type Props = {
  onYesClicked?: () => void;
  onNoClicked?: () => void;
} & DialogProps;

export const ConfirmYoungAdultDeletionDialog = ({ onYesClicked, onNoClicked, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <DialogTitle>
        <Typography variant="h6">{CONFIRM_YOUNG_ADULT_DELETION_DIALOG_TITLE}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <StyledButton fullWidth={true} onClick={onYesClicked}>
            {CONFIRM_YOUNG_ADULT_DELETION_DIALOG_YES_BUTTON}
          </StyledButton>
          <StyledButton fullWidth={true} variant="contained" onClick={onNoClicked}>
            {CONFIRM_YOUNG_ADULT_DELETION_DIALOG_NO_BUTTON}
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
