import { CircularProgress, Typography } from '@mui/material';
import { Observation } from '~/modules/autonomyProgression/infrastructure/getAutonomyDataProgressionData.dto';
import { AutonomyLevelsIcons } from '~/modules/autonomyProgression/views/AutonomyLevelsIcons';
import { AutonomyNoProgressionAvailable } from '~/modules/autonomyProgression/views/AutonomyNoProgressionAvailable';
import { AutonomyProgressionChart } from '~/modules/autonomyProgression/views/AutonomyProgressionChart';
import { AutonomyProgressionEmpty } from '~/modules/autonomyProgression/views/AutonomyProgressionEmpty';
import {
  StyledAutonomyProgressionSection,
  StyledAutonomyProgressionVisualization,
} from '~/modules/autonomyProgression/views/AutonomyProgressionSection.style';
import { ObservationDomain } from '~/modules/observation/domain/observationDomains';
import { Population } from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { theme } from '~/shared/theme/theme';

export const AUTONOMY_PROGRESSION_TITLE = "Progression vers l'autonomie";
export const DEFAULT_DOMAIN_TAB_SELECTED: ObservationDomain = 'employmentAccess';

export type AutonomyProgressionSectionProps = {
  observations: Observation[];
  isLoading?: boolean;
  population: Population | undefined;
  selectedDomain: ObservationDomain;
};
export const AutonomyProgressionSection = ({
  observations,
  isLoading,
  population,
  selectedDomain,
}: AutonomyProgressionSectionProps) => {
  const isPopulationOne = population === Population.Population1;

  if (isLoading === true)
    return (
      <StyledAutonomyProgressionVisualization>
        <CircularProgress />
      </StyledAutonomyProgressionVisualization>
    );

  if (isLoading === false && isPopulationOne) {
    return <AutonomyNoProgressionAvailable />;
  }

  if (observations.length === 0) {
    return <AutonomyProgressionEmpty />;
  }

  return (
    <StyledAutonomyProgressionSection>
      <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
        {AUTONOMY_PROGRESSION_TITLE}
      </Typography>
      <StyledAutonomyProgressionVisualization>
        <AutonomyLevelsIcons />
        <AutonomyProgressionChart observations={observations} domain={selectedDomain} />
      </StyledAutonomyProgressionVisualization>
    </StyledAutonomyProgressionSection>
  );
};
