import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import { theme } from '~/shared/theme/theme';

export const StyledDialog = styled(Dialog)({
  borderRadius: 8,
});

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

export const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
});

export const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
