import AddIcon from '@mui/icons-material/Add';
import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AddYoungAdultDialog,
  AddYoungAdultFormValues,
} from '~/modules/progressDashboard/components/AddYoungAdultDialog';
import { getSnackbarColor, getSnackbarMessage } from '~/modules/progressDashboard/domain/snackbar';
import { serializeNationalSupportPlan } from '~/modules/progressDashboard/domain/validateValues';
import { useYoungAdultMutations } from '~/modules/progressDashboard/infrastructure/useYoungAdultFileMutations';
import { FOLLOW_YOUNG_ADULT_SNACKBAR_LABEL } from '~/modules/progressDashboard/views/ProgressionDashboardCard/FollowYoungAdultForm';
import { StyledButton } from '~/shared/components/StyledButton';

const ADD_YOUNG_ADULT_BUTTON_LABEL = 'add-young-adult';
const ADD_YOUNG_ADULT_BUTTON_TEXT = 'Ajouter un usager';

const ADD_YOUNG_ADULT_ALREADY_FOLLOWED_MSG = 'Cet usager figure déjà dans votre liste';
const ADD_YOUNG_ADULT_ERROR_MSG = "Impossible d'ajouter cet usager";
const ADD_YOUNG_ADULT_SUCCESS_MSG = "L'usager a bien été ajouté";

export const AddYoungAdultForm = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSnackbarVisible, showSnackbar] = useState(false);

  const { addYoungAdultMutation } = useYoungAdultMutations();

  const snackbarMessage = {
    alreadyFollowed: ADD_YOUNG_ADULT_ALREADY_FOLLOWED_MSG,
    error: ADD_YOUNG_ADULT_ERROR_MSG,
    success: ADD_YOUNG_ADULT_SUCCESS_MSG,
  };

  const submit = (values: AddYoungAdultFormValues) => {
    const serializedValues = {
      ...values,
      lastname: values.lastname.toUpperCase(),
      imiloId: uuidv4(),
      nationalSupportPlan: serializeNationalSupportPlan(values.nationalSupportPlan),
    };
    addYoungAdultMutation.mutate(serializedValues, { onSettled: () => showSnackbar(true) });

    setIsDialogOpen(false);
  };

  return (
    <>
      <StyledButton
        color="primary"
        variant="contained"
        aria-label={ADD_YOUNG_ADULT_BUTTON_LABEL}
        endIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ height: 'fit-content', width: 'fit-content' }}
      >
        {ADD_YOUNG_ADULT_BUTTON_TEXT}
      </StyledButton>
      {isDialogOpen && (
        <AddYoungAdultDialog
          onCancelClicked={() => setIsDialogOpen(false)}
          onClose={() => setIsDialogOpen(false)}
          onSubmitClicked={submit}
          open={isDialogOpen}
        />
      )}
      <Snackbar
        open={isSnackbarVisible}
        onClose={() => showSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-label={FOLLOW_YOUNG_ADULT_SNACKBAR_LABEL}
      >
        <Alert severity={getSnackbarColor(addYoungAdultMutation.isSuccess)} variant="filled">
          {getSnackbarMessage(snackbarMessage, addYoungAdultMutation.error ?? undefined)}
        </Alert>
      </Snackbar>
    </>
  );
};
