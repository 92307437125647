import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useNavigate } from 'react-router-dom';
import { SURVEY_ROUTE_PATH } from '~/core/router';
import { StyledButton } from '~/shared/components/StyledButton';

const NEW_DIAGNOSIS_BUTTON_TEXT = 'Lancer un diagnostic';
export const NEW_DIAGNOSIS_BUTTON_LABEL_PREFIX = 'lancer-un-diagnostic';

export const DiagnosisButton = ({ imiloId }: { imiloId: string }) => {
  const navigate = useNavigate();

  const navigateToObervationPage: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event?.stopPropagation();
    navigate(`${SURVEY_ROUTE_PATH}/${imiloId}`);
  };

  return (
    <StyledButton
      color="primary"
      variant="contained"
      aria-label={`${NEW_DIAGNOSIS_BUTTON_LABEL_PREFIX}-${imiloId}`}
      onClick={navigateToObervationPage}
      endIcon={<ArrowForwardRoundedIcon />}
    >
      {NEW_DIAGNOSIS_BUTTON_TEXT}
    </StyledButton>
  );
};
