import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { validateObservationForSubmission } from '~/modules/observation/domain/observationCompletion';
import { ObservationScores } from '~/modules/observation/domain/observationScores';
import {
  StyledButtonWithTooltip,
  StyledButtonWithTooltipProps,
} from '~/shared/components/StyledButtonWithTooltip';

export const SUBMIT_TEXT = 'Enregistrer le diagnostic';

type SubmitDiagnosisButtonProps = Omit<
  StyledButtonWithTooltipProps,
  'endIcon' | 'variant' | 'color' | 'disabled' | 'tooltipProps'
> & { observationScores: ObservationScores };

export const SubmitDiagnosisButton = ({
  observationScores,
  ...props
}: SubmitDiagnosisButtonProps) => {
  const { isValid: isSubmissionAllowed, reason: tooltipText } =
    validateObservationForSubmission(observationScores);

  return (
    <StyledButtonWithTooltip
      {...props}
      color="primary"
      variant="contained"
      endIcon={<ArrowForwardRoundedIcon />}
      disabled={!isSubmissionAllowed}
      tooltipProps={{ title: tooltipText }}
    >
      {SUBMIT_TEXT}
    </StyledButtonWithTooltip>
  );
};
