import { ProgressionDataPoint } from '~/modules/autonomyProgression/domain/progressionChartData.schema';

export const observationLabelFormatter = (value: string) => {
  return `Diag n°${value}`;
};

// TECHNICAL DEBT - Cannot find a way to type this correctly because the typing is flawed : it does not allow
// null values in datapoints whereas we can use null values in reality
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type TooltipFormatterParams = {
  value: ProgressionDataPoint;
  componentIndex: number;
  componentType: 'series' | 'markArea';
  componentSubType: string;
  name: string;
  seriesName: string;
  marker: string;
  dataIndex: number;
  data: ProgressionDataPoint;
  $vars: string[];
};

export const formatQuestionValue = (value: unknown) => {
  if (value === null) return 'Non observé';
  return `${value} / 5`;
};

export const tooltipFormatter = (params: TooltipFormatterParams[]) => {
  let tooltipContent = `<b>Diagnostic n°${params[0].value.observationNumber} - ${params[0].value.observationDate}</b><br/>`;

  params.forEach((series, index) => {
    const question = `question${index + 1}`;
    let value = null;
    switch (question) {
      case 'question1':
      case 'question2':
      case 'question3':
        value = series.value[question];
        break;
    }
    tooltipContent += `<br/>${series.marker} ${series.seriesName}: ${formatQuestionValue(value)}`;
  });
  return tooltipContent;
};
