import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Path, UseFormRegister } from 'react-hook-form';
import { StyledSelect } from '~/shared/components/StyledSelect';

export const SelectFormControl = <FormValues extends Record<string, unknown>, OptionValues>({
  defaultValue,
  fieldName,
  items,
  label,
  register,
  required,
  translation,
}: {
  defaultValue: OptionValues | '';
  fieldName: Path<FormValues>;
  items: string[];
  label: string;
  register: UseFormRegister<FormValues>;
  required?: boolean;
  translation?: Record<string, string>;
}): JSX.Element => {
  return (
    <FormControl variant="outlined" sx={{ width: '100%' }}>
      <InputLabel id={`${fieldName}-label`} required={required}>
        {label}
      </InputLabel>
      <StyledSelect
        {...register(fieldName)}
        aria-placeholder={label}
        defaultValue={defaultValue}
        fullWidth
        inputProps={{
          'aria-label': label,
        }}
        label={label}
        labelId={`${fieldName}-label`}
        name={fieldName}
        required={required}
        sx={{ height: 'fit-content' }}
      >
        {items?.map((item) => (
          <MenuItem value={item} key={item}>
            {translation !== undefined ? translation[item] : item}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};
