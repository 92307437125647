import { CircularProgress, Fade, Typography } from '@mui/material';
import { StyledLoadingContainer } from '~/core/components/auth/LoadingPage.style';

export const LoadingPage = () => {
  return (
    <Fade
      in={true}
      style={{
        transitionDelay: '800ms',
      }}
      unmountOnExit
    >
      <StyledLoadingContainer>
        <CircularProgress />
        <Typography variant="body1">Chargement de la page en cours...</Typography>
      </StyledLoadingContainer>
    </Fade>
  );
};
